import web3 from "web3";

export const convertToWei = (value, decimal) => {
  if (value && decimal) {
    if (Number(decimal) === 18)
      return web3.utils.toWei(value.toString(), "ether");
    else if (Number(decimal) === 6)
      return web3.utils.toWei(value.toString(), "picoether");
    else if (Number(decimal) === 9)
      return web3.utils.toWei(value.toString(), "nanoether");
    else if (Number(decimal) === 12)
      return web3.utils.toWei(value.toString(), "microether");
    else if (Number(decimal) === 15)
      return web3.utils.toWei(value.toString(), "milliether");
    else return value.toWei() / 10 ** Number(decimal);
  } else {
    return "";
  }
};

export const convertFromWei = (value, decimal) => {
  try {
    if (Number(decimal) === 18)
      return web3.utils.fromWei(value.toString(), "ether");
    else if (Number(decimal) === 6)
      return web3.utils.fromWei(value.toString(), "picoether");
    else if (Number(decimal) === 9)
      return web3.utils.fromWei(value.toString(), "nanoether");
    else if (Number(decimal) === 12)
      return web3.utils.fromWei(value.toString(), "microether");
    else if (Number(decimal) === 15)
      return web3.utils.fromWei(value.toString(), "milliether");
    else return value.toString() / 10 ** Number(decimal);
  } catch (error) {
    return 0;
  }
};

export const convertFixed = (value) => {
  if (Number(value) === 0)
    // nếu value < 0.00000009
    return value
      ?.toFixed(2)
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // thì show giá trị 9 sốthập phân
  else if (Number(value) > 1e3)
    // nếu value lớn hơn 1e5 (100000)
    return value
      ?.toFixed(0)
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ","); //thì show giá trị 0 số thập phân
  else if (Number(value) < 1 && Number(value) >= 9e-4)
    // nếu 1 > value >= 0.0009
    return value?.toFixed(4); // thì show giá trị 4 sốthập phân
  else if (Number(value) < 9e-4 && Number(value) >= 9e-7)
    // nếu value < 0.00000009
    return value?.toFixed(7); // thì show giá trị 9 sốthập phân
  else return value.toFixed(2);
};

export const formatNumber = (value, decimals = 18) => {
  try {
    const number = parseFloat(value);
    return number.toFixed(decimals).replace(/\.?0+$/, "");
  } catch (error) {
    return "0";
  }
};
