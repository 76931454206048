import { SwapWrapper } from "../../Swap/styled";
import {
  BlockRouter,
  BlockRouterInner,
  HeadingRouter,
  NoFoundRouter,
} from "../../Bridge/styled";
import { useContext, useEffect } from "react";
import { ContextProviderWrapper } from "../../../components/Context";
import { formatNumber } from "../../../utils/convertNumber";
import LoadingSpin from "../../../components/Common/Loading";

interface RouterInterface {
  pathRouter: any;
  setIsShowRouterMobile: any;
  isLoadingRouter: any;
  setClickRouter: any;
  clickRouter: any;
  setSelectedToData: any;
  setRouterDetail: any;
}
const RouterV2 = ({
  pathRouter,
  setIsShowRouterMobile,
  isLoadingRouter,
  setClickRouter,
  clickRouter,
  setSelectedToData,
  setRouterDetail,
}: RouterInterface) => {
  console.log("pathRouter", pathRouter?.results);
  const { isDesktop, isMobile } = useContext(ContextProviderWrapper)!;

  const formatToTwoDecimals = (number: number) => {
    return Math.floor(number * 100) / 100;
  };

  const calculateRouterDetails = (swaps: any[]) => {
    const totalTime = swaps.reduce(
      (total: number, item: any) => total + (item?.estimatedTimeInSeconds || 0),
      0
    );

    const totalNetworkFee = swaps.reduce((total: number, swap: any) => {
      const networkFee = swap?.fee
        ?.filter((fee: any) => fee.name === "Network Fee")
        .reduce(
          (feeTotal: number, fee: any) => feeTotal + fee.amount * fee.price,
          0
        );
      return total + networkFee;
    }, 0);

    const intermediaryLogos = swaps.flatMap((swap: any) => {
      if (swap?.internalSwaps?.length > 0) {
        // If internalSwaps exist, gather their logos
        return swap?.internalSwaps?.map(
          (internalSwap: any) => internalSwap.swapperLogo
        );
      } else {
        // If internalSwaps do not exist, take swapperLogo
        return swap?.swapperLogo ? [swap?.swapperLogo] : [];
      }
    });

    // Remove duplicates from intermediaryLogos
    const uniqueLogos = Array.from(new Set(intermediaryLogos));

    return { totalTime, totalNetworkFee, intermediaryLogos: uniqueLogos };
  };

  useEffect(() => {
    if (pathRouter?.results?.length > 0) {
      const firstItem = pathRouter.results[0];
      const lastSwap = firstItem?.swaps?.[firstItem.swaps.length - 1];
      if (lastSwap) {
        setSelectedToData({
          amount: Number(lastSwap.toAmount),
          usdPrice: lastSwap.to.usdPrice,
        });
      }
      setClickRouter({
        id: 0,
        requestId: firstItem.requestId,
      });

      const { totalTime, totalNetworkFee } = calculateRouterDetails(
        firstItem?.swaps
      );
      const intermediaryLogos = firstItem?.swaps?.flatMap(
        (swap: any) =>
          swap?.internalSwaps?.map(
            (internalSwap: any) => internalSwap.swapperLogo
          ) || []
      );
      setRouterDetail({ totalTime, totalNetworkFee, intermediaryLogos });
    }
  }, [pathRouter]);

  return (
    <SwapWrapper
      className={
        pathRouter?.results?.length <= 0
          ? "no-router"
          : isLoadingRouter
          ? "is-loading"
          : ""
      }
    >
      {isLoadingRouter ? (
        <LoadingSpin />
      ) : pathRouter?.results?.length > 0 ? (
        <>
          <HeadingRouter>
            {isDesktop ? (
              <>
                <h3 className="title-router">Best Route Recommend</h3>
                <span className="time-router">
                  <img
                    src="/assets/images/bridge/icn_reload.svg"
                    width={13}
                    height={13}
                    alt="Reload"
                  />
                  20sec
                </span>
              </>
            ) : (
              <h3
                className="title-router is-flex"
                onClick={() => setIsShowRouterMobile(false)}
              >
                <img
                  src="/assets/images/bridge/icn_arrow_left_white.svg"
                  alt="Back to Bridge"
                />
                Back to Bridge
              </h3>
            )}
          </HeadingRouter>
          {pathRouter?.results?.map((items: any, index: number) => (
            <BlockRouter
              className={
                clickRouter.requestId === items?.requestId ? "is-border" : ""
              }
              onClick={() => {
                console.log("items", items);
                const { totalTime, totalNetworkFee, intermediaryLogos } =
                  calculateRouterDetails(items?.swaps);
                setRouterDetail({
                  totalTime,
                  totalNetworkFee,
                  intermediaryLogos,
                });
                const lastSwap = items?.swaps?.[items.swaps.length - 1];
                if (lastSwap) {
                  setSelectedToData({
                    amount: Number(lastSwap.toAmount),
                    usdPrice: lastSwap.to.usdPrice,
                  });
                }
                setClickRouter({
                  id: index,
                  requestId: items.requestId,
                });
                if (isMobile) {
                  setIsShowRouterMobile(false);
                }
              }}
            >
              <div className="block-top">
                {(() => {
                  const { totalTime, totalNetworkFee } = calculateRouterDetails(
                    items?.swaps
                  );
                  return (
                    <>
                      <ul className="list-function">
                        <li>Cost: ${formatToTwoDecimals(totalNetworkFee)}</li>
                        <li>Time: {totalTime} sec</li>
                      </ul>
                      <ul className="list-commend">
                        <li className="low-fee">Low fee</li>
                        <li className="recommend">Recommend</li>
                      </ul>
                    </>
                  );
                })()}
              </div>
              <BlockRouterInner>
                {items?.swaps?.map((item: any, index: number) => (
                  <>
                    <div
                      key={index}
                      className={`block-bottom ${
                        items?.swaps?.length <= 1 ? "is-full" : ""
                      }`}
                    >
                      <div className="router-detail">
                        <div className="router-from">
                          <div className="router-logo">
                            <div className="router-left">
                              <img
                                src={item?.from?.logo}
                                width={33}
                                height={33}
                                loading="lazy"
                                alt="Icon chain"
                              />
                              <img
                                src={item?.from?.blockchainLogo}
                                width={16}
                                height={16}
                                className="icon-small"
                                loading="lazy"
                                alt="Icon chain"
                              />
                            </div>
                            <div className="router-right">
                              <span className="token">
                                {formatToTwoDecimals(
                                  Number(
                                    formatNumber(
                                      item?.fromAmount,
                                      item?.from?.decimals
                                    )
                                  )
                                )}{" "}
                                {/* {item?.from?.symbol} */}
                              </span>
                              <span className="price">
                                $
                                {formatToTwoDecimals(
                                  Number(
                                    formatNumber(
                                      item?.fromAmount,
                                      item?.from?.decimals
                                    )
                                  ) * item?.from?.usdPrice
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {item?.internalSwaps?.length > 0 ? (
                        <div className="router-intermediary">
                          <img
                            src={
                              isDesktop
                                ? items?.swaps?.length <= 1
                                  ? "/assets/images/bridge/icn_line_02.svg"
                                  : "/assets/images/bridge/icn_line.svg"
                                : "/assets/images/bridge/icn_arrow_right_grey.svg"
                            }
                            width={76}
                            height={6}
                            loading="lazy"
                            alt="Icon line"
                            className="icon-line"
                          />
                          {item?.internalSwaps?.map(
                            (itemRouter: any, index: number) => (
                              <img
                                key={index}
                                src={itemRouter?.swapperLogo}
                                width={14}
                                height={14}
                                className="icon-small icon-logo"
                                loading="lazy"
                                alt="Icon chain"
                              />
                            )
                          )}
                        </div>
                      ) : (
                        <div className="router-intermediary">
                          <img
                            src={
                              isDesktop
                                ? items?.swaps?.length <= 1
                                  ? "/assets/images/bridge/icn_line_02.svg"
                                  : "/assets/images/bridge/icn_line.svg"
                                : "/assets/images/bridge/icn_arrow_right_grey.svg"
                            }
                            width={76}
                            height={6}
                            loading="lazy"
                            alt="Icon line"
                            className="icon-line"
                          />
                          <img
                            key={index}
                            src={item?.swapperLogo}
                            width={14}
                            height={14}
                            className="icon-small icon-logo"
                            loading="lazy"
                            alt="Icon chain"
                          />
                        </div>
                      )}
                      <div className="router-detail">
                        <div className="router-to">
                          <div className="router-logo">
                            <div className="router-left">
                              <img
                                src={item?.to?.logo}
                                width={33}
                                height={33}
                                loading="lazy"
                                alt="Icon chain"
                              />
                              <img
                                src={item?.to?.blockchainLogo}
                                width={16}
                                height={16}
                                className="icon-small"
                                loading="lazy"
                                alt="Icon chain"
                              />
                            </div>
                            <div className="router-right">
                              <span className="token">
                                {formatToTwoDecimals(Number(item?.toAmount))}{" "}
                                {/* {item?.to?.symbol} */}
                              </span>
                              <span className="price">
                                $
                                {formatToTwoDecimals(
                                  item?.toAmount * item?.to?.usdPrice
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {items?.swaps?.length > 1 && (
                      <div className="block-line-middle">
                        <img
                          src={
                            isDesktop
                              ? "/assets/images/bridge/icn_line.svg"
                              : "/assets/images/bridge/icn_arrow_right_grey.svg"
                          }
                          width={76}
                          height={6}
                          loading="lazy"
                          alt="Icon line"
                          className="icon-line"
                        />
                      </div>
                    )}
                  </>
                ))}
              </BlockRouterInner>
            </BlockRouter>
          ))}
        </>
      ) : (
        <NoFoundRouter>
          <div className="no-image">
            <img
              src="/assets/images/bridge/icn_no_router.png"
              width={204}
              height={109}
              loading="lazy"
              alt="No router"
            />
          </div>
          <p>No route found</p>
        </NoFoundRouter>
      )}
    </SwapWrapper>
  );
};

export default RouterV2;
