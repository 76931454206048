import axios from "axios";
import {
  BridgeNetworkList,
  BridgeSelect,
  TokenNetworkContainer,
} from "./styled";
import { useContext, useEffect, useState } from "react";
import {
  ChooseTokenContainer,
  ChooseTokenSearch,
  ChooseTokenWrapper,
} from "../../../Swap/ChooseToken/styled";
import { ContextProviderWrapper } from "../../../../components/Context";
import { Controller, useForm } from "react-hook-form";
import InputCommon from "../../../../components/Common/Input";
import clear from "../../../../assets/Dashboard/Common/clear.svg";
import search_icon from "../../../../assets/Dashboard/Common/search_icon.svg";
import default_token_image from "../../../../assets/Dashboard/Common/default-token-image.png";
import LoadingSpin from "../../../../components/Common/Loading";

type FormValuesProps = {
  search: string;
  setChainList: any;
  chainList: any;
};

const TokenAndNetwork = ({
  setNameChain,
  setOpenModal,
  setDataToken,
  isFrom,
  setChainList,
  chainList,
}: any) => {
  const KIB_API_RANGO: any = process.env.REACT_APP_KIBBLE_RANGO;
  const { theme } = useContext(ContextProviderWrapper)!;
  const [tokenList, setTokenList] = useState([]);
  const [catchSelect, setCatchSelect] = useState({
    chainName: "ETH",
    token: "",
  });
  const [loading, setIsLoading] = useState(false);
  const [dataChain, setDataCbain] = useState<any>();

  const handleGetChains = async () => {
    try {
      const res = await axios.get(
        `https://api.rango.exchange/meta/blockchains?apiKey=${KIB_API_RANGO}`
      );
      if (res.status === 200) {
        setChainList(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetTokenByChainFrom = async () => {
    setIsLoading(true);
    try {
      const res: any = await axios.get(
        `https://api.rango.exchange/meta?excludeNonPopulars=true&blockchains=${catchSelect.chainName}&apiKey=${KIB_API_RANGO}`
      );
      if (res) {
        setTokenList(res?.data?.tokens);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (catchSelect.chainName) {
      handleGetTokenByChainFrom();
    }
  }, [catchSelect.chainName]);

  // const handleGetTokenBySearch = async () => {
  //   try {
  //     const res = await axios.get(
  //       `https://li.quest/v1/token?chain=56&token=0x55d398326f99059ff775485246999027b3197955`
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    handleGetChains();
  }, []);

  const defaultValues = {
    search: "",
  };

  const methods = useForm<FormValuesProps>({
    defaultValues,
    mode: "onChange",
  });
  const { control } = methods;

  //const name
  const handleGetDataTokenForm = (item: any) => {
    console.log("item", item);
    if (isFrom) {
      setNameChain({
        // logoChainFrom: item.blockchainImage ? item.blockchainImage : item.image,
        nameChainFrom: item.blockchain,
        logoChainFrom: dataChain.logo,
        nameTokenFrom: item.name ? item.name : item.symbol,
        logoTokenFrom: item.image,
        priceTokenFrom: item.usdPrice,
      });
    } else {
      setNameChain({
        // logoChainTo: item.blockchainImage ? item.blockchainImage : item.image,
        nameChainTo: item.blockchain,
        logoChainTo: dataChain.logo,
        nameTokenTo: item.name ? item.name : item.symbol,
        logoTokenTo: item.image,
        priceTokenTo: item.usdPrice,
      });
    }
    setOpenModal(false);
    setDataToken(item);
  };

  return (
    <TokenNetworkContainer>
      {/* Search chain */}
      <ChooseTokenContainer>
        <ChooseTokenWrapper className={theme}>
          <ChooseTokenSearch className={theme}>
            <Controller
              name="search"
              control={control}
              render={({ field }: any) => (
                <InputCommon
                  {...field}
                  // disabled={loadingState ? true : false}
                  placeHolder="Search by chain name or chain id..."
                  name="search"
                  allowClear={{ clearIcon: <img src={clear} alt="icon" /> }}
                  prefix={<img src={search_icon} alt="icon" />}
                />
              )}
            />
            <p>
              <span>Network</span>
            </p>
          </ChooseTokenSearch>
          <BridgeNetworkList>
            {chainList?.map((item: any, index: number) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    setCatchSelect((prev) => ({
                      ...prev,
                      chainName: item.name,
                    }));
                    setDataCbain(item);
                  }}
                >
                  <figure>
                    <img
                      width={24}
                      height={24}
                      src={item.logo}
                      alt="icon"
                      loading="lazy"
                    />
                  </figure>
                  <p>{item.name ? item.name : "--"}</p>
                </li>
              );
            })}
          </BridgeNetworkList>
        </ChooseTokenWrapper>
      </ChooseTokenContainer>

      {/* Search token */}
      <ChooseTokenContainer>
        <ChooseTokenWrapper className={theme}>
          <ChooseTokenSearch className={theme}>
            <Controller
              name="search"
              control={control}
              render={({ field }: any) => (
                <InputCommon
                  {...field}
                  // disabled={loadingState ? true : false}
                  placeHolder="Search by symbol or name..."
                  name="search"
                  allowClear={{ clearIcon: <img src={clear} alt="icon" /> }}
                  prefix={<img src={search_icon} alt="icon" />}
                />
              )}
            />
            <p>
              <span>Token</span>
            </p>
          </ChooseTokenSearch>
          <BridgeSelect>
            {loading ? (
              <LoadingSpin />
            ) : (
              <BridgeNetworkList>
                {tokenList?.map((item: any, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        handleGetDataTokenForm(item);
                      }}
                    >
                      <figure>
                        <img
                          width={24}
                          height={24}
                          src={item.image}
                          alt="icon"
                          loading="lazy"
                          onError={(e) =>
                            (e.currentTarget.src = default_token_image)
                          }
                        />
                      </figure>
                      <p>{item.name ? item.name : item.symbol}</p>
                    </li>
                  );
                })}
              </BridgeNetworkList>
            )}
          </BridgeSelect>
        </ChooseTokenWrapper>
      </ChooseTokenContainer>
    </TokenNetworkContainer>
  );
};

export default TokenAndNetwork;
