import styled from "styled-components";

export const BridgeRouteItem = styled.li`
  border-radius: 0px 0px 8px 8px;
  background: rgba(216, 216, 216, 0.1);
  backdrop-filter: blur(6px);
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;
export const RouteHeader = styled.div`
  border-radius: 10px;
  background: #363636;
  padding: 15px;
`;
export const RouteBlock = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;
export const BlockRouteIcon = styled.div`
  position: relative;
  figure {
    &:nth-child(1) {
      width: 32px;
      height: 33px;
    }
    &:nth-child(2) {
      position: absolute;
      width: 17px;
      height: 16px;
      bottom: -5px;
      right: -5px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
`;
export const BlockRouteContent = styled.div`
  p {
    color: #fff;
    font-family: Aspekta-500;
    font-size: 15px;
    margin-bottom: -5px;
  }
  span {
    color: #6b6b6b;
    font-size: 12px;
  }
`;
export const RouterBlockRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const RouterBlockLine = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: calc(100% - 320px);
  & > div {
    figure {
      position: relative;
      width: 21px;
      height: 21px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
`;
export const RouteEstimate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 16px;
  .route-inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;
export const RouterLine = styled.div`
  position: absolute;
  background-color: #fff;
  width: 100%;
  left: 0;
  top: 50%;
  height: 2px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
  }
  &::before {
    width: 8px;
    height: 8px;
    background-color: #fff;
    left: 0;
    margin-top: -4px;
  }
  &::after {
    right: -8px;
    width: 0;
    height: 0;
    margin-top: -4px;
    border-top: 6px solid transparent;
    border-left: 12px solid #0280ff;
    border-bottom: 6px solid transparent;
  }
`;
export const EstimateTag = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  padding: 4px 8px;
  border-radius: 6px;
  gap: 5px;
`;
export const EstimateContent = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  p {
    color: #007af5;
    text-align: right;
    font-family: Aspekta-500;
    font-size: 13px;
    span {
      color: #fff;
    }
  }
`;

//test main router
export const MainRouter = styled.div`
  margin-bottom: 20px;
  .block-time {
    color: #fff;
  }
  .gas {
    color: #fff;
  }
  .main-inner {
    display: flex;
  }
  .block {
    display: flex;
    width: 100%;
    height: 100px;
    &.special {
      .image-to {
        display: none;
      }
    }
  }
  .closed {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .closed-inner {
    width: 100%;
    display: flex;
    height: 100%;
  }
  .image-from {
    min-width: 42px;
    position: relative;
  }
  .item-swap {
    width: 100%;
    height: 50%;
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-color: red;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 2px;
  }
  .item-swap-inner {
    display: flex;
    justify-content: center;
  }
  .price {
    color: #fff;
  }
`;
