import { useEffect, useState } from "react";
import { convertFromWei, formatNumber } from "../../../utils/convertNumber";
import {
  BlockRouteContent,
  BlockRouteIcon,
  BridgeRouteItem,
  EstimateContent,
  EstimateTag,
  MainRouter,
  RouteBlock,
  RouteEstimate,
  RouteHeader,
  RouterBlockLine,
  RouterBlockRow,
  RouterLine,
} from "./styled";
import { TypeRoterInterface } from "../typeRouter";

const RouteItem = ({ item, setClickRouter, clickRouter, number }: any) => {
  console.log("item", item);

  // Tính tổng thời gian
  const totalTimeInSeconds = item?.swaps?.reduce(
    (total: number, swap: any) => total + (swap.estimatedTimeInSeconds || 0),
    0
  );

  // Chuyển đổi tổng thời gian thành hours, minutes, seconds
  const hours = Math.floor(totalTimeInSeconds / 3600);
  const minutes = Math.floor((totalTimeInSeconds % 3600) / 60);
  const seconds = totalTimeInSeconds % 60;

  // Hiển thị định dạng thời gian
  const formattedTime = [
    hours > 0 ? `${hours}h` : null,
    minutes > 0 ? `${minutes}m` : null,
    `${seconds}s`, // Luôn hiển thị giây
  ]
    .filter(Boolean) // Loại bỏ null
    .join(" ");

  // Tính tổng Network Fee
  const totalNetworkFee = item?.swaps?.reduce((total: number, swap: any) => {
    const networkFee = swap?.fee
      ?.filter((fee: any) => fee.name === "Network Fee")
      .reduce(
        (feeTotal: number, fee: any) => feeTotal + fee.amount * fee.price,
        0
      );
    return total + networkFee;
  }, 0);

  // Tính tổng các phí khác
  const totalFees = item?.swaps?.reduce((total: number, swap: any) => {
    const swapFees = swap?.fee?.reduce((feeTotal: number, fee: any) => {
      return feeTotal + fee.amount * fee.price;
    }, 0);
    return total + swapFees;
  }, 0);

  const totalSteps = item?.swaps?.length || 0;

  return (
    <>
      <MainRouter
        style={{
          border:
            item.requestId === clickRouter.requestId
              ? "1px solid red"
              : "unset",
        }}
        onClick={() => {
          setClickRouter({
            id: number,
            requestId: item.requestId,
          });
        }}
      >
        <div className="block-time">time: {formattedTime}</div>
        <div className="gas">gas: ${totalNetworkFee.toFixed(2)}</div>
        <div style={{ fontSize: "1rem", color: "#fff", marginBottom: "10px" }}>
          {totalSteps} Steps
        </div>
        <div className="main-inner">
          {item?.swaps?.map((items: any, index: number) => (
            <>
              <div
                className={`block ${
                  index === item?.swaps?.length - 1 ? "" : "special"
                }`}
                key={index}
              >
                <div className="closed">
                  <div className="closed-inner">
                    <div className="image-from">
                      <div style={{ textAlign: "center" }}>
                        <img
                          src={items?.from?.logo}
                          width={20}
                          height={20}
                          loading="lazy"
                          alt=""
                        />
                        <p className="price">
                          {formatNumber(
                            items?.fromAmount,
                            items?.from?.decimals
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="item-swap">
                      {items?.internalSwaps ? (
                        <div className="item-swap-inner">
                          {items?.internalSwaps?.map(
                            (itemSwap: any, index: number) => (
                              <div>
                                <div className="logo-middle">
                                  <img
                                    src={itemSwap?.swapperLogo}
                                    width={20}
                                    height={20}
                                    loading="lazy"
                                    alt=""
                                  />
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="item-swap-inner">
                          <div className="logo-middle">
                            <img
                              src={items?.swapperLogo}
                              width={20}
                              height={20}
                              loading="lazy"
                              alt=""
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="image-to">
                      <div className="div">
                        <img
                          src={items?.to?.logo}
                          width={20}
                          height={20}
                          loading="lazy"
                          alt=""
                        />
                        <p className="price">{item?.outputAmount}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </MainRouter>
    </>
    // <BridgeRouteItem>
    //   <RouteHeader>
    //     <RouteBlock>
    //       <RouterBlockRow>
    //         <BlockRouteIcon>
    //           <figure>
    //             <img
    //               src={item?.from?.image}
    //               alt={item?.from?.symbol}
    //               loading="lazy"
    //             />
    //           </figure>
    //           <figure>
    //             <img
    //               src={item?.from?.image}
    //               alt={item?.from?.symbol}
    //               loading="lazy"
    //             />
    //           </figure>
    //         </BlockRouteIcon>
    //         <BlockRouteContent>
    //           <p>
    //             {Number(
    //               convertFromWei(item?.inputAmount, item?.to?.decimals)
    //             ).toFixed(2)}
    //           </p>
    //           {/* <span>{item?.starter?.blockchain}</span> */}
    //         </BlockRouteContent>
    //       </RouterBlockRow>
    //       <RouterBlockLine>
    //         <RouterLine />
    //         {/* {pathSwapper?.map((itemLine: any, index: any) => {
    //           return (
    //             <div key={index}>
    //               <figure>
    //                 <img src={itemLine?.logo} alt={item.title} loading="lazy" />
    //               </figure>
    //             </div>
    //           );
    //         })} */}
    //       </RouterBlockLine>
    //       <RouterBlockRow>
    //         <BlockRouteIcon>
    //           <figure>
    //             <img src={item?.to?.image} alt="icon" loading="lazy" />
    //           </figure>
    //           <figure>
    //             <img src={item?.to?.image} alt="icon" loading="lazy" />
    //           </figure>
    //         </BlockRouteIcon>
    //         <BlockRouteContent>
    //           <p>
    //             ~
    //             {Number(
    //               convertFromWei(item?.expectedOutput, item?.to?.decimals)
    //             ).toFixed(2)}
    //           </p>
    //           {/* <span>
    //             {convertFromWei(item?.expectedOutput, item?.to?.decimals)}
    //           </span> */}
    //         </BlockRouteContent>
    //       </RouterBlockRow>
    //     </RouteBlock>
    //   </RouteHeader>
    //   <RouteEstimate>
    //     <div className="route-inner">
    //       <EstimateTag
    //         style={{
    //           background: item?.bestTime
    //             ? "rgba(65, 129, 206, 0.25)"
    //             : "rgba(185, 65, 206, 0.25)",
    //           color: item?.bestTime ? "#7BB2FF" : "#FF7BF6",
    //         }}
    //       >
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           width="18"
    //           height="19"
    //           viewBox="0 0 18 19"
    //           fill="none"
    //         >
    //           <path
    //             d="M16.5 4.78262V11.9676C16.5 14.0376 14.82 15.7176 12.75 15.7176H5.24999C4.90499 15.7176 4.57499 15.6726 4.25249 15.5826C3.78749 15.4551 3.63749 14.8626 3.98249 14.5176L11.955 6.54512C12.12 6.38012 12.3675 6.34262 12.6 6.38762C12.84 6.43262 13.1025 6.36512 13.29 6.18512L15.2175 4.25012C15.9225 3.54512 16.5 3.77762 16.5 4.78262Z"
    //             fill={item?.bestTime ? "#7BB2FF" : "#FF7BF6"}
    //           />
    //           <path
    //             d="M10.98 6.02014L3.1275 13.8726C2.7675 14.2326 2.1675 14.1426 1.9275 13.6926C1.65 13.1826 1.5 12.5901 1.5 11.9676V4.78264C1.5 3.77764 2.0775 3.54514 2.7825 4.25014L4.7175 6.19264C5.01 6.47764 5.49 6.47764 5.7825 6.19264L8.4675 3.50014C8.76 3.20764 9.24 3.20764 9.5325 3.50014L10.9875 4.95514C11.2725 5.24764 11.2725 5.72764 10.98 6.02014Z"
    //             fill={item?.bestTime ? "#7BB2FF" : "#FF7BF6"}
    //           />
    //         </svg>
    //         <p>{item?.bestTime ? "Best Time" : "Best Fee"}</p>
    //       </EstimateTag>
    //       <EstimateContent>
    //         <p>
    //           Time: <span>{item?.estimatedTimeInSeconds / 60} mins</span>
    //         </p>
    //         {/* <p>
    //           Gas: <span>~{dataFee?.feeUsd?.toFixed(2)} USD</span>
    //         </p> */}
    //       </EstimateContent>
    //     </div>
    //   </RouteEstimate>
    // </BridgeRouteItem>
  );
};

export default RouteItem;
