import moment from "moment";
import {
  HistoryTransactions,
  HistoryTransactionsList,
  TransactionsAction,
  TransactionsButton,
  TransactionsDetail,
  TransactionsDetailCouple,
  TransactionsDetailLine,
  TransactionsDetailStatus,
  TransactionsDetailTitle,
  TransactionsList,
  TransactionsTime,
  TransactionsTitle,
} from "../style";
import { Coins } from "ton3-core";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useTonAddress } from "@tonconnect/ui-react";
import { shortenAddress } from "../../../../utils/addressUser";
import { CopyOutlined } from "@ant-design/icons";
import { copyToClipboard } from "../../../../utils/copyClipBoard";

const HistoryList = () => {
  const [indexShowDetailTx, setindexShowDetailTx] = useState<any>(-1);
  const [dataHistory, setDataHistory] = useState<any>([]);
  const { allAssets }: any = useSelector((state: any) => state.assets);
  const address = useTonAddress();
  const handleGetHistory = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/transactions/by-user?user_wallet=${address}&is_confirm=true`
      );
      if (data && allAssets) {
        const updatedData = data.map((item: any) => {
          const assetFrom = allAssets.find(
            (asset: any) => asset.contract_address === item.offer_jetton_address
          );
          const assetTo = allAssets.find(
            (asset: any) => asset.contract_address === item.ask_jetton_address
          );

          return {
            ...item,
            assetFrom: assetFrom,
            assetTo: assetTo,
          };
        });
        setDataHistory(updatedData);
      }
    } catch (error) {
      console.log("errrr", error);
    }
  };

  useEffect(() => {
    handleGetHistory();
  }, []);

  return (
    <HistoryTransactionsList>
      {dataHistory.length < 1 ? (
        <p
          style={{
            color: "#fff",
            fontSize: "16px",
            textAlign: "center",
          }}
        >
          Don't have any transaction
        </p>
      ) : (
        <>
          {dataHistory?.map((item: any, index: number) => {
            return (
              <HistoryTransactions
                key={index}
                style={{
                  height: indexShowDetailTx === index ? "210px" : "42px",
                }}
              >
                <TransactionsTitle>
                  <TransactionsTime>
                    <span>
                      {moment
                        .unix(item.valid_until)
                        .utc()
                        .format("MMMM DD , YYYY")}
                    </span>
                    <h2>
                      +{" "}
                      {Coins.fromNano(
                        item?.min_ask_amount,
                        item.assetTo?.decimals
                      ).toString()}{" "}
                      {item.assetTo.symbol}
                    </h2>
                  </TransactionsTime>
                  <TransactionsAction>
                    {indexShowDetailTx === index ? (
                      <TransactionsButton
                        onClick={() => {
                          setindexShowDetailTx(-1);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <rect
                            x="22"
                            y="22"
                            width="22"
                            height="22"
                            rx="11"
                            transform="rotate(-180 22 22)"
                            fill="#141518"
                          />
                          <path
                            d="M6.5481 12.3455L10.4753 8.41822C10.7651 8.12845 11.2349 8.12845 11.5247 8.41822L15.4519 12.3455"
                            stroke="#F7F7F8"
                            stroke-width="1.48397"
                            stroke-linecap="round"
                          />
                        </svg>
                      </TransactionsButton>
                    ) : (
                      <TransactionsButton
                        onClick={() => {
                          setindexShowDetailTx(index);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="23"
                          viewBox="0 0 22 23"
                          fill="none"
                        >
                          <rect
                            y="0.200043"
                            width="22"
                            height="22"
                            rx="11"
                            fill="#141518"
                          />
                          <path
                            d="M15.4519 9.85461L11.5247 13.7819C11.2349 14.0716 10.7651 14.0716 10.4753 13.7819L6.5481 9.85461"
                            stroke="#F7F7F8"
                            stroke-width="1.48397"
                            stroke-linecap="round"
                          />
                        </svg>
                      </TransactionsButton>
                    )}
                  </TransactionsAction>
                </TransactionsTitle>
                <TransactionsList>
                  <TransactionsDetail key={index}>
                    <TransactionsDetailTitle>
                      <TransactionsDetailCouple>
                        <div className="icon-1">
                          <img src="" alt="" />
                        </div>
                        <div className="icon-1">
                          <img src="" alt="" />
                        </div>
                        <h1>
                          {item.assetFrom.symbol}/{item.assetTo.symbol}
                        </h1>
                      </TransactionsDetailCouple>
                      <TransactionsDetailStatus>
                        <span>{item.is_confirmed && "Succeed"}</span>
                      </TransactionsDetailStatus>
                    </TransactionsDetailTitle>
                    <TransactionsDetailTitle>
                      <span>
                        <img
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                          src={item.assetFrom.image_url}
                          alt="icon"
                        />
                        {item.assetFrom.symbol}
                      </span>
                      <h2
                        style={{
                          color: "#f50f48",
                        }}
                      >
                        -$
                        {Coins.fromNano(
                          item?.offer_amount,
                          item.assetFrom?.decimals
                        ).toString()}
                      </h2>
                    </TransactionsDetailTitle>
                    <TransactionsDetailTitle>
                      <span>
                        <img
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                          src={item.assetTo.image_url}
                          alt="icon"
                        />
                        {item.assetTo.symbol}
                      </span>
                      <h2
                        style={{
                          color: "rgb(15, 245, 134)",
                        }}
                      >
                        +$
                        {Coins.fromNano(
                          item?.min_ask_amount,
                          item.assetTo?.decimals
                        ).toString()}
                      </h2>
                    </TransactionsDetailTitle>
                    <TransactionsDetailTitle>
                      <span>Transaction hash</span>
                      {item.tx_hash ?
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px'
                        }}>
                          <a style={{
                            color: "#fff"
                          }} href={`https://tonviewer.com/transaction/${item.tx_hash}`} target="_blank" rel="noreferrer">
                            {shortenAddress(item.tx_hash)}
                          </a>
                            <div
                              onClick={() => {
                                copyToClipboard(item.tx_hash, "Copied !!")
                              }}
                              style={{
                                color: "#92929E",
                                cursor: "pointer",
                              }}
                            >
                              <CopyOutlined />
                            </div>
                        </div>
                        : <h2>--</h2>}
                    </TransactionsDetailTitle>
                    <TransactionsDetailLine>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="292"
                        height="2"
                        viewBox="0 0 292 2"
                        fill="none"
                      >
                        <path
                          d="M1 1.20004L291 1.20007"
                          stroke="#43424A"
                          stroke-linecap="round"
                          stroke-dasharray="5 5"
                        />
                      </svg>
                    </TransactionsDetailLine>
                    <TransactionsDetailTitle>
                      <p
                        style={{
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        {moment.unix(item.valid_until).utc().format("HH:mm:ss")}
                      </p>
                    </TransactionsDetailTitle>
                  </TransactionsDetail>
                </TransactionsList>
              </HistoryTransactions>
            );
          })}
        </>
      )}
    </HistoryTransactionsList>
  );
};

export default HistoryList;
