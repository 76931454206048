import styled from "styled-components";
import { ChooseTokenContainer } from "../../../Swap/ChooseToken/styled";
import { LoadingSpinContainer } from "../../../../components/Common/Loading/styled";

export const TokenNetworkContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  ${ChooseTokenContainer} {
    &:nth-child(1) {
      width: 35%;
    }
    &:nth-child(2) {
      width: 65%;
    }
  }
`;
export const BridgeNetworkList = styled.ul`
  width: 100%;
  max-height: 310px;
  overflow-y: auto;
  & > li {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px;
    border-radius: 4px;
    background: #282828;
    overflow: hidden;
    transition: all 0.15s linear;
    cursor: pointer;
    &:hover {
      background-color: rgba(40, 40, 40, 0.5);
    }
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    p {
      font-size: 16px;
      color: #fff;
      font-weight: 600;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    figure {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`;
export const BridgeSelect = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  ${LoadingSpinContainer} {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    p {
      width: max-content;
      font-size: 15px;
    }
  }
`;
