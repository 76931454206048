import Footer from "./components/Footer";
import { AppContainer } from "./styled";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ContextProviderWrapper } from "./components/Context";
import { Toaster } from "react-hot-toast";
import { useContext, useEffect } from "react";
import LayoutDashboard from "./Layout/Dashboard";
import Swap from "./page/Swap";
import Farm from "./page/Farm";
import Launchpad from "./page/Launchpad";
import Liquidity from "./page/Liquidity";
import Community from "./page/Community";
import { store } from "./store/store";
import { Provider } from "react-redux";
import WebApp from "@twa-dev/sdk";
import IDODetail from "./page/Launchpad/Detail";
import AddLiquidity from "./page/Liquidity/Add";
import CreatePoolLiquidity from "./page/Liquidity/CreatePool";
import LiquidityDetails from "./page/Liquidity/Details";
import LayoutLiquidity from "./Layout/Liquidity";
import ChartCommon from "./page/Chart";
import GlobalStyle from "./GlobalStyle";
import Kibble from "./page/Kibble";
import LeaderBoard from "./page/LeaderBoard";
import Bridge from "./page/Bridge";
import BridgeV2 from "./page/Bridgev2";
import { createAppKit } from "@reown/appkit/react";
import { BaseWalletAdapter, SolanaAdapter } from "@reown/appkit-adapter-solana";
import { WagmiAdapter } from "@reown/appkit-adapter-wagmi";
import {
  AppKitNetwork,
  avalanche,
  bsc,
  mainnet,
  solana,
} from "@reown/appkit/networks";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  SolflareWalletAdapter,
  PhantomWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { WagmiProvider } from "wagmi";

global.Buffer = Buffer;
global.process = process;

const App = () => {
  const { pathname } = useLocation();
  const { theme, isMobile } = useContext(ContextProviderWrapper)!;

  useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  useEffect(() => {
    WebApp.ready();
  }, []);

  const queryClient = new QueryClient();
  const networks: any = [bsc as AppKitNetwork, solana, mainnet, avalanche];
  const projectId = "c84624b4b35e21d900b3f8f307122687";
  const wagmiAdapter = new WagmiAdapter({
    projectId,
    networks,
  });

  const solanaWeb3JsAdapter = new SolanaAdapter({
    wallets: [
      new PhantomWalletAdapter() as unknown as BaseWalletAdapter<string>,
      new SolflareWalletAdapter() as unknown as BaseWalletAdapter<string>,
    ],
  });

  const metadata = {
    name: "Kibble",
    description: "",
    url: "",
    icons: [""],
  };

  createAppKit({
    adapters: [wagmiAdapter, solanaWeb3JsAdapter],
    networks: networks,
    metadata: metadata,
    projectId,
    themeMode: "dark",
    features: {
      swaps: false,
      analytics: false,
      onramp: false,
      socials: false,
      emailShowWallets: false,
      email: false,
    },
  });

  return (
    <WagmiProvider config={wagmiAdapter.wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <TonConnectUIProvider manifestUrl="https://app.kibble.exchange/static/tonconnect-manifest.json">
            <Toaster
              position="top-right"
              reverseOrder={false}
              toastOptions={{
                style: {
                  fontFamily: "Syne",
                },
              }}
            />
            <GlobalStyle theme={theme} />
            <AppContainer
              className={`${theme} ${
                pathname === "/kibble" && theme === "dark"
                  ? "is-kibble"
                  : pathname === "/kibble" && theme === "light"
                  ? `is-kibble ${theme}`
                  : ""
              }`}
              pathname={pathname === "/"}
            >
              <Routes>
                <Route element={<LayoutDashboard />}>
                  <Route index path="swap" element={<Swap />} />
                  <Route index path="leaderboard" element={<LeaderBoard />} />
                  <Route index path="chart" element={<ChartCommon />} />
                  <Route path="staking" element={<Farm />} />
                  <Route path="bridge2" element={<Bridge />} />
                  <Route path="bridge" element={<BridgeV2 />} />
                  <Route path="launchpad" element={<Launchpad />} />
                  <Route path="kibble" element={<Kibble />} />
                  <Route path="launchpad/:id" element={<IDODetail />} />
                  <Route path="liquidity" element={<LayoutLiquidity />}>
                    <Route index element={<Liquidity />} />
                    <Route path="provide" element={<AddLiquidity />} />
                    <Route path="details/:id" element={<LiquidityDetails />} />
                    <Route path="init" element={<CreatePoolLiquidity />} />
                  </Route>
                  <Route path="community-tool" element={<Community />} />
                </Route>
                <Route path="*" element={<Navigate to="/swap" />} />
              </Routes>
              {!isMobile && <Footer />}
            </AppContainer>
          </TonConnectUIProvider>
        </Provider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default App;
