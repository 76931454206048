import TitleCommon from "../../components/Common/Title";
import {
  InfoValue,
  LeaderBoardBenifitTag,
  LeaderBoardBg,
  LeaderBoardContainer,
  LeaderBoardTop,
  ListTotal,
  ShowInfoValue,
  ShowListTokens,
  ShowRank,
  ShowRankInfo,
  TableBody,
  TableBodyMB,
  TableBodyMBWrap,
  TableBodyRowMB,
  TableCell,
  TableContent,
  TableHeader,
  TableHeaderMB,
  TableRow,
  TotalItem,
  WrapTable,
  WrapTableMB,
  WrapTop,
} from "./styled";
import { useContext } from "react";
import { ContextProviderWrapper } from "../../components/Context";
import { toast } from "react-hot-toast";
import { shortenAddress } from "../../utils/addressUser";

export type LeaderBoardAction = "offer" | "ask";

const LeaderBoard = () => {
  const { theme, isMobile } = useContext(ContextProviderWrapper)!;

  const benifitTags = [
    {
      title: "Top rank",
      color: theme === "light" ? "#01B85F" : "#0FF586",
    },
    {
      title: "More reward",
      color: theme === "light" ? "#E6A519" : "#F0CC54",
    },
  ];

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Copied!");
    });
  };

  return (
    <LeaderBoardBg>
      <LeaderBoardContainer>
        <WrapTop>
          <LeaderBoardTop>
            <TitleCommon text={"kibble leaderboard"} />
            <LeaderBoardBenifitTag className={theme}>
              {benifitTags.map((item, index) => {
                return (
                  <li
                    style={{
                      color: item.color,
                    }}
                    key={index}
                  >
                    <span
                      style={{
                        background: item.color,
                      }}
                    ></span>
                    {item.title}
                  </li>
                );
              })}
            </LeaderBoardBenifitTag>
          </LeaderBoardTop>
          <ListTotal>
            {!isMobile &&
              LIST_TOTAL.map((item: any, index: number) => (
                <TotalItem key={index}>
                  <h1>{item.title}</h1>
                  <p>{item.value}</p>
                  {index > 0 && <div className="line-right"></div>}
                </TotalItem>
              ))}
            {isMobile &&
              LIST_TOTAL_MB.map((item: any, index: number) => (
                <TotalItem key={index}>
                  <h1>{item.title}</h1>
                  <p>{item.value}</p>
                  {index > 0 && <div className="line-right"></div>}
                  <div className="img-k">
                    <img src="/assets/images/leaderboard/img-k.png" alt="" />
                  </div>
                </TotalItem>
              ))}
          </ListTotal>
        </WrapTop>
        {isMobile ? (
          <WrapTableMB>
            <TableHeaderMB>
              <ShowRank>
                <h1>17326</h1>
              </ShowRank>
              <ShowRankInfo>
                <div className="row-between">
                  <h1>Your Rank</h1>
                  <div className="row-between">
                    <ShowListTokens>
                      <div className="img-token">
                        <img
                          src="/assets/images/leaderboard/icon-bit.png"
                          alt="img"
                        />
                      </div>
                      <div className="img-token">
                        <img
                          src="/assets/images/leaderboard/icon-eth.png"
                          alt="img"
                        />
                      </div>
                      <div className="img-around">
                        <p>+5</p>
                      </div>
                    </ShowListTokens>
                    <div
                      className="img-copy"
                      onClick={() =>
                        copyToClipboard(
                          "EQAvlWFDxGF2lXm67y4yzC17wYKD9A0guwPkMs1gOsM__NOT"
                        )
                      }
                    >
                      <img
                        src="/assets/images/leaderboard/img-copy.png"
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
                <ShowInfoValue>
                  <InfoValue>
                    <h2>Vol.</h2>
                    <p>$53,579</p>
                  </InfoValue>
                  <InfoValue>
                    <h2>Txn</h2>
                    <p>72</p>
                  </InfoValue>
                </ShowInfoValue>
              </ShowRankInfo>
            </TableHeaderMB>
            <TableBodyMBWrap>
              <TableBodyMB>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((item: any, index: number) => (
                  <TableBodyRowMB key={index}>
                    <ShowRank>
                      <h1>{index + 1}</h1>
                    </ShowRank>
                    <ShowRankInfo>
                      <div className="row-between">
                        <h1>
                          {shortenAddress(
                            "EQAvlWFDxGF2lXm67y4yzC17wYKD9A0guwPkMs1gOsM__NOT"
                          )}{" "}
                        </h1>
                        <div className="row-between">
                          <ShowListTokens>
                            <div className="img-token">
                              <img
                                src="/assets/images/leaderboard/icon-bit.png"
                                alt="img"
                              />
                            </div>
                            <div className="img-token">
                              <img
                                src="/assets/images/leaderboard/icon-eth.png"
                                alt="img"
                              />
                            </div>
                            <div className="img-around">
                              <p>+5</p>
                            </div>
                          </ShowListTokens>
                          {/* <CopyToClipboard
                            text={
                              "EQAvlWFDxGF2lXm67y4yzC17wYKD9A0guwPkMs1gOsM__NOT"
                            }
                            onCopy={() => {
                              toast.success("Copied !!");
                            }}
                          >
                            <div className="img-copy">
                              <img
                                src="/assets/images/leaderboard/img-copy-white.png"
                                alt="img"
                              />
                            </div>
                          </CopyToClipboard> */}
                        </div>
                      </div>
                      <ShowInfoValue>
                        <InfoValue>
                          <h2>Vol.</h2>
                          <p>$53,579</p>
                        </InfoValue>
                        <InfoValue>
                          <h2>Txn</h2>
                          <p>72</p>
                        </InfoValue>
                      </ShowInfoValue>
                    </ShowRankInfo>
                  </TableBodyRowMB>
                ))}
              </TableBodyMB>
            </TableBodyMBWrap>
          </WrapTableMB>
        ) : (
          <WrapTable>
            <TableContent>
              <TableHeader>
                {TABLE_HEADER.map((item: any, index: number) => (
                  <TableCell key={index}>
                    <h1>{item}</h1>
                  </TableCell>
                ))}
              </TableHeader>
              <TableBody>
                {TABLE_BODY.map((item: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {shortenAddress(
                        "EQAvlWFDxGF2lXm67y4yzC17wYKD9A0guwPkMs1gOsM__NOT"
                      )}{" "}
                      {/* <CopyToClipboard
                        text={item.tx_hash}
                        onCopy={() => {
                          toast.success("Copied !!");
                        }}
                      >
                        <div
                          style={{
                            color: "#92929E",
                            cursor: "pointer",
                          }}
                        >
                          <CopyOutlined />
                        </div>
                      </CopyToClipboard> */}
                    </TableCell>
                    <TableCell>{item.vol}</TableCell>{" "}
                    <TableCell>
                      {item.tokens.map((icon: any, jdex: number) => (
                        <div key={jdex} className="img-icon">
                          <img src={icon} alt="icon" />
                        </div>
                      ))}
                      <div className="img-around">
                        <p>+5</p>
                      </div>
                    </TableCell>{" "}
                    <TableCell>{item.total}</TableCell>{" "}
                  </TableRow>
                ))}
              </TableBody>
            </TableContent>
          </WrapTable>
        )}
      </LeaderBoardContainer>
    </LeaderBoardBg>
  );
};

const LIST_TOTAL = [
  {
    title: "Total Wallet",
    value: "12,099",
  },
  {
    title: "Total Volume",
    value: "$ 125,954,023,124",
  },
  {
    title: "Total Transaction",
    value: "935,996",
  },
];

const LIST_TOTAL_MB = [
  {
    title: "Total Wallet",
    value: "12,953",
  },
  {
    title: "Total Volume",
    value: "$125M",
  },
  {
    title: "Total Tx hash",
    value: "936K",
  },
];

const TABLE_HEADER = [
  "Rank",
  "Wallet Address",
  "Volume",
  "Recent Tokens",
  "Total Transaction",
];

const TABLE_BODY = [
  {
    address: "0sd3...12fe",
    vol: "$1,095,559",
    tokens: [
      "/assets/images/leaderboard/icon-bit.png",
      "/assets/images/leaderboard/icon-eth.png",
      "/assets/images/leaderboard/icon-bnb.png",
      "/assets/images/leaderboard/icon-bit.png",
      "/assets/images/leaderboard/icon-eth.png",
    ],
    total: "352",
  },
  {
    address: "0sd3...12fe",
    vol: "$1,095,559",
    tokens: [
      "/assets/images/leaderboard/icon-bit.png",
      "/assets/images/leaderboard/icon-eth.png",
      "/assets/images/leaderboard/icon-bnb.png",
      "/assets/images/leaderboard/icon-bit.png",
      "/assets/images/leaderboard/icon-eth.png",
    ],
    total: "352",
  },
  {
    address: "0sd3...12fe",
    vol: "$1,095,559",
    tokens: [
      "/assets/images/leaderboard/icon-bit.png",
      "/assets/images/leaderboard/icon-eth.png",
      "/assets/images/leaderboard/icon-bnb.png",
      "/assets/images/leaderboard/icon-bit.png",
      "/assets/images/leaderboard/icon-eth.png",
    ],
    total: "352",
  },
  {
    address: "0sd3...12fe",
    vol: "$1,095,559",
    tokens: [
      "/assets/images/leaderboard/icon-bit.png",
      "/assets/images/leaderboard/icon-eth.png",
      "/assets/images/leaderboard/icon-bnb.png",
      "/assets/images/leaderboard/icon-bit.png",
      "/assets/images/leaderboard/icon-eth.png",
    ],
    total: "352",
  },
  {
    address: "0sd3...12fe",
    vol: "$1,095,559",
    tokens: [
      "/assets/images/leaderboard/icon-bit.png",
      "/assets/images/leaderboard/icon-eth.png",
      "/assets/images/leaderboard/icon-bnb.png",
      "/assets/images/leaderboard/icon-bit.png",
      "/assets/images/leaderboard/icon-eth.png",
    ],
    total: "352",
  },
  {
    address: "0sd3...12fe",
    vol: "$1,095,559",
    tokens: [
      "/assets/images/leaderboard/icon-bit.png",
      "/assets/images/leaderboard/icon-eth.png",
      "/assets/images/leaderboard/icon-bnb.png",
      "/assets/images/leaderboard/icon-bit.png",
      "/assets/images/leaderboard/icon-eth.png",
    ],
    total: "352",
  },
  {
    address: "0sd3...12fe",
    vol: "$1,095,559",
    tokens: [
      "/assets/images/leaderboard/icon-bit.png",
      "/assets/images/leaderboard/icon-eth.png",
      "/assets/images/leaderboard/icon-bnb.png",
      "/assets/images/leaderboard/icon-bit.png",
      "/assets/images/leaderboard/icon-eth.png",
    ],
    total: "352",
  },
  {
    address: "0sd3...12fe",
    vol: "$1,095,559",
    tokens: [
      "/assets/images/leaderboard/icon-bit.png",
      "/assets/images/leaderboard/icon-eth.png",
      "/assets/images/leaderboard/icon-bnb.png",
      "/assets/images/leaderboard/icon-bit.png",
      "/assets/images/leaderboard/icon-eth.png",
    ],
    total: "352",
  },
  {
    address: "0sd3...12fe",
    vol: "$1,095,559",
    tokens: [
      "/assets/images/leaderboard/icon-bit.png",
      "/assets/images/leaderboard/icon-eth.png",
      "/assets/images/leaderboard/icon-bnb.png",
      "/assets/images/leaderboard/icon-bit.png",
      "/assets/images/leaderboard/icon-eth.png",
    ],
    total: "352",
  },
];

export default LeaderBoard;
