import styled from "styled-components";
import { TitleContainer } from "../../components/Common/Title/styled";
import bg from "../../assets/Dashboard/Swap/bg_swap.png";
import coin_header from "../../assets/Dashboard/Swap/coin_header.png";
import { TabSelect } from "../Liquidity/styled";

export const LeaderBoardBg = styled.div`
  &::before,
  &::after {
    content: "";
    position: absolute;
    pointer-events: none;
  }
  &::before {
    left: 53%;
    top: 10%;
    width: 202px;
    height: 320px;
    background-color: #5255c6;
    mix-blend-mode: plus-lighter;
    filter: blur(173px);
  }
  &::after {
    bottom: 0;
    left: 0;
    width: 100%;
    background: url(${bg}) no-repeat center / cover;
    height: 100%;
  }
  @media screen and (max-width: 767px) {
    &:before {
      content: none;
    }
  }
`;
export const LeaderBoardContainer = styled.div`
  position: relative;
  z-index: 5;
  max-width: 1300px;
  margin: 0 auto;
  padding: 60px 15px;
  .ant-input-disabled {
    color: #fff !important;
  }
  @media screen and (max-width: 767px) {
    padding-top: 20px;
    padding-bottom: 100px;
    ${TabSelect} {
      margin-bottom: 10px;
    }
  }
`;

export const LeaderBoardBenifitTag = styled.ul`
  display: flex;
  align-items: center;
  gap: 5px;
  &.light {
    & > li {
      background: #e8ebf8;
    }
  }
  & > li {
    display: flex;
    width: max-content;
    gap: 10px;
    padding: 8px 16px;
    align-items: center;
    border-radius: 41px;
    background: rgba(214, 243, 255, 0.13);
    backdrop-filter: blur(6.949999809265137px);
    font-size: 15px;
    font-weight: 500;
    span {
      display: block;
      border-radius: 50%;
      width: 8px;
      height: 8px;
    }
  }
  @media screen and (max-width: 767px) {
    & > li {
      padding: 8px;
    }
  }
`;
export const LeaderBoardTop = styled.div`
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 100%;
    top: -30px;
    background: url(${coin_header}) no-repeat center / 100% auto;
    width: 61px;
    height: 60px;
  }
  ${TitleContainer} {
    font-size: 36px;
    margin-bottom: 10px;
    line-height: normal;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
    &::before {
      top: -70px;
      background: unset;
    }
  }
`;

export const WrapTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  @media screen and (max-width: 767px) {
    gap: 0;
  }
`;

export const ListTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 17px;
  border-radius: 12px;
  border: 1px solid var(--Neutral-800, #43424a);
  background: #1c1c1e;
  /* Drop Box */
  box-shadow: 0px 0px 85.4px 0px rgba(106, 126, 228, 0.15);
  @media screen and (max-width: 767px) {
    background: none;
    border: none;
    box-shadow: none;
    gap: 5px;
    padding: 0;
    width: 100%;
    align-items: stretch;
  }
`;

export const TotalItem = styled.div`
  position: relative;
  padding: 0px 20px;
  h1 {
    color: var(--Neutral-400, #92929e);
    text-align: center;
    font-family: "DMMono-400";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 17.5px */
    margin-bottom: 5px;
  }
  p {
    color: var(--Neutral-50, #f7f7f8);
    text-align: center;
    font-family: "DMMono-400";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 111.111% */
    letter-spacing: -1.08px;
  }
  .line-right {
    width: 1px;
    height: 43px;
    background: var(--Neutral-500, #6e6e7c);
    position: absolute;
    top: 0;
    left: 0px;
  }
  @media screen and (max-width: 767px) {
    border-radius: 10.12px;
    border: 1px solid #404040;
    background: #1c1c1e;
    padding: 14px 12px;
    width: calc(100% / 3);
    h1 {
      text-align: left;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 15px */
      margin-bottom: 10px;
    }
    p {
      text-align: right;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 90.909% */
      letter-spacing: -1.32px;
    }
    .line-right {
      display: none;
    }
    .img-k {
      max-width: 90px;
      position: absolute;
      top: 5px;
      left: 0;
      img {
        width: 100%;
      }
    }
  }
`;

export const WrapTable = styled.div`
  position: relative;
  max-width: 100%;
  margin-top: 20px;
  overflow: auto;
  border-radius: 12px;
  border: 1px solid var(--Neutral-800, #43424a);
`;

export const TableContent = styled.div`
  position: relative;
  width: 100%;
  min-width: 1274px;
  background-color: #1c1c1e;
`;

export const TableHeader = styled.div`
  position: relative;
  display: flex;
  padding: 22px 24px 12px 24px;
  justify-content: space-between;
  align-items: center;
  background: #2c2c2c;
  h1 {
    color: var(--Neutral-50, #f7f7f8);
    text-align: center;
    font-family: "DMMono-500";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 111.111% */
    letter-spacing: -1.08px;
  }
`;

export const TableBody = styled.div`
  position: relative;
  width: 100%;
`;

export const TableRow = styled.div`
  position: relative;
  display: flex;
  padding: 12px 24px;
  align-items: center;
  align-self: stretch;
`;
export const TableCell = styled.div`
  position: relative;
  display: flex;
  padding: 0px 38px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  &:nth-child(1) {
    width: 50px;
  }
  &:nth-child(2) {
    width: 300px;
  }
  &:nth-child(3) {
    width: 70px;
  }
  &:nth-child(4) {
    width: 300px;
  }
  &:nth-child(5) {
    width: 200px;
  }
  .img-icon {
    max-width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -15px;
    img {
      width: 100%;
    }
  }
  .img-around {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-left: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.64px solid #000;
    background: var(--Neutral-White, #fff);
    p {
      color: #000;
      font-family: "DMMono-500";
      font-size: 12.8px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 15.36px */
      letter-spacing: -0.256px;
    }
  }
  color: var(--Neutral-200, #d9d9de);
  text-align: center;
  font-family: "DMMono-500";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.96px;
`;

export const WrapTableMB = styled.div`
  position: relative;
  margin-top: 20px;
`;

export const TableHeaderMB = styled.div`
  display: flex;
  height: 81px;
  padding: 12px 8px;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  align-self: stretch;
  background: var(--a, linear-gradient(99deg, #b5ebff 0%, #edfaff 58.42%));
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
`;

export const ShowRank = styled.div`
  display: flex;
  padding: 18px 6px 20px 7px;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  background: rgba(151, 170, 255, 0.2);
  h1 {
    color: var(--Blue--Pri-700, #007af5);
    text-align: center;
    font-family: "DMMono-500";
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 22px */
    text-transform: lowercase;
  }
`;

export const ShowRankInfo = styled.div`
  width: 100%;
  .row-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  h1 {
    color: var(--Base-Black, #000);
    font-family: "DMMono-500";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 100% */
    white-space: nowrap;
  }

  .img-around {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin-left: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.64px solid #000;
    background: var(--Neutral-White, #fff);
    p {
      color: #000;
      font-family: "DMMono-500";
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.256px;
    }
  }
  .img-copy {
    max-width: 24px;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
`;
export const ShowListTokens = styled.div`
  display: flex;
  .img-token {
    max-width: 24px;
    margin-left: -5px;
    img {
      width: 100%;
    }
  }
`;

export const ShowInfoValue = styled.div`
  display: flex;
  gap: 4px;
  width: 100%;
`;
export const InfoValue = styled.div`
  display: flex;
  padding: 5px 8px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.84);
  width: calc(100% / 2);
  h2 {
    color: #868686;
    text-align: center;
    font-family: Syne;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 16.5px */
  }
  p {
    color: var(--Neutral-900, #3a3a40);
    text-align: center;
    font-family: "DMMono-500";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 18px */
  }
`;

export const TableBodyMBWrap = styled.div`
  position: relative;
  overflow: scroll;
  height: calc(100vh - 530px);
  background: #1c1c1e;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
`;

export const TableBodyMB = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
`;

export const TableBodyRowMB = styled.div`
  display: flex;
  padding: 6px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 10px;
  border: 0.778px solid #323232;
  background: #18191c;
  ${ShowRank} {
    display: flex;
    width: 60px;
    padding: 18px 13px;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    background: var(--Blue--Pri-950, #0e335d);
  }
  ${ShowRankInfo} {
    h1 {
      color: var(--Blue--Pri-600, #0693ff);
      font-family: "DMMono-400";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 100% */
      text-transform: lowercase;
    }
  }
  ${InfoValue} {
    border-radius: 4px;
    background: #2c2c2c;
    p {
      color: var(--Blue--Pri-200, #b5ebff);
      text-align: center;
      font-family: "DMMono-400";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 18px */
    }
  }
`;
