/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from "react";
import TitleCommon from "../../components/Common/Title";
import {
  SwapBenifitTag,
  SwapBg,
  SwapChartFilter,
  SwapContainer,
  SwapInputValue,
  SwapTop,
  SwapWrapper,
} from "../Swap/styled";
import {
  BridgeContainer,
  BridgeForm,
  BridgeHeader,
  BridgeInner,
  BridgeInput,
  BridgeRouteList,
  BridgeSelect,
  BridgeSuffix,
  BridgeSwitch,
  BridgeTabs,
  RouteText,
} from "./styled";
import { ContextProviderWrapper } from "../../components/Context";
import { Controller, useForm } from "react-hook-form";
import InputCommon from "../../components/Common/Input";
import swap_exchange from "../../assets/Dashboard/Swap/swap_exchange.png";
import { Checkbox, Switch } from "antd";
import RouteItem from "./RouteItem";
import bnb_icon from "../../assets/Bridge/bnb_icon.png";
import usdt_icon from "../../assets/Bridge/usdt_icon.png";
import bnb_chain_icon from "../../assets/Bridge/bnb_chain_icon.png";
import avax_chain_icon from "../../assets/Bridge/avax_chain_icon.png";
import solana_chain_icon from "../../assets/Bridge/solana_chain_icon.png";
import chain_icon from "../../assets/Bridge/chain_icon.png";
import axios from "axios";
import ModalOverlay from "../../components/Common/ModalOverlay";
import { tokenFromInterface, TypeRoterInterface } from "./typeRouter";
import { useDebounce } from "../../hooks/useDebounce";
import LoadingSpin from "../../components/Common/Loading";
import {
  useAppKit,
  useAppKitAccount,
  useWalletInfo,
} from "@reown/appkit/react";
import {
  CreateTransactionRequest,
  RangoClient,
  CheckApprovalResponse,
  TransactionStatusResponse,
  TransactionStatus,
  TransactionType,
} from "rango-sdk";
import { ethers } from "ethers";
import TokenAndNetwork from "./Modal/Token&Network";
import { ButtonCommon } from "../../Layout/styled";
import {
  logApprovalResponse,
  logRouteStep,
  logStepStatus,
  logTransactionHash,
} from "../../utils/logger";

declare const window: Window & typeof globalThis & { ethereum: any };

const Bridge = () => {
  const KIB_API_RANGO: any = process.env.REACT_APP_KIBBLE_RANGO;
  const INPUT_DEBOUNCE = 1000;
  const { open } = useAppKit();
  const { address }: any = useAppKitAccount();
  const { theme } = useContext(ContextProviderWrapper)!;
  const [tabClicked, setTabClicked] = useState(1);
  const { control } = useForm({ mode: "onChange" });
  const [openFromModal, setOpenFromModal] = useState(false);
  const [openToModal, setOpenToModal] = useState(false);
  const [clickRouter, setClickRouter] = useState({
    id: 0,
    requestId: "",
  });
  const [nameChainFrom, setNameChainFrom] = useState({
    nameChainFrom: "",
    nameTokenFrom: "",
  });
  const [nameChainTo, setNameChainTo] = useState({
    nameChainTo: "",
    nameTokenTo: "",
  });
  const [valueInputFrom, setValueInputFrom] = useState();
  const [pathRouter, setPathRouter] = useState<any>();
  const [dataFee, setDataFee] = useState<TypeRoterInterface | undefined>();
  const [dataTokenFrom, setDataTokenFrom] = useState<any>();
  const [dataTokenTo, setDataTokenTo] = useState<any>();
  const [isLoadingRouter, setIsLoadingRouter] = useState<boolean | null>(false);
  const [isLoadingDataRouter, setIsLoadingDataRouter] = useState<
    boolean | null
  >(false);

  const benifitTags = [
    {
      title: "Lighting fast",
      color: theme === "light" ? "#E6A519" : "#F0CC54",
    },
    {
      title: "Best rate",
      color: theme === "light" ? "#DC622E" : "#DC622E",
    },
  ];

  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };

  const handleCheckbox = () => {};

  const routeFilter = [
    {
      id: 1,
      value: 0,
      text: (
        <RouteText>
          Fastest
          <span>{pathRouter?.path?.length}</span>
        </RouteText>
      ),
    },
    {
      id: 2,
      value: 1,
      text: "Best Fee",
    },
  ];

  //getBestRoutes
  const getBestRoutes = async () => {
    setIsLoadingRouter(true);
    try {
      const response = await axios.post(
        "https://api.rango.exchange/routing/bests",
        {
          // enableCentralizedSwappers: true,
          from: {
            blockchain: `${dataTokenFrom?.blockchain}`,
            symbol: `${dataTokenFrom?.symbol}`,
            address: dataTokenFrom?.address,
          },
          // swappersGroupsExclude: true,
          to: {
            blockchain: `${dataTokenTo?.blockchain}`,
            symbol: `${dataTokenTo?.symbol}`,
            address: `${dataTokenTo?.address}`,
          },
          amount: valueInputFrom,
          slippage: "1",
        },
        {
          params: {
            apiKey: KIB_API_RANGO,
          },
          headers: {
            "content-type": "application/json",
          },
        }
      );
      if (response) {
        setIsLoadingDataRouter(false);
        setIsLoadingRouter(false);
        setPathRouter(response?.data);
      }
    } catch (error) {
      setIsLoadingDataRouter(false);
    }
  };
  console.log("pathRouter", pathRouter);

  // Debounced function
  const debouncedHandleGetBridge = useDebounce(() => {
    // handleGetBridge();
    getBestRoutes();
    // setIsLoadingDataRouter(false);
  }, INPUT_DEBOUNCE);

  // Input change handler
  const onChangeInputFrom = (e: any) => {
    setValueInputFrom(e.target.value);
    setIsLoadingDataRouter(true);
  };

  // Trigger debounced function on input change
  useEffect(() => {
    if (Number(valueInputFrom) > 0) {
      debouncedHandleGetBridge();
    }
  }, [valueInputFrom]);

  // Call the function

  const rango = new RangoClient("a259b891-a9d9-48e6-8a62-ee1f3fba2653");

  const [confirmRouterTest, setConfirmRouterTest] = useState<any>();
  console.log("confirmRouterTest", confirmRouterTest);
  const selectedWallets = pathRouter?.results[clickRouter.id]?.swaps
    ?.flatMap((swap: any) => [swap.from.blockchain, swap.to.blockchain])
    .filter(
      (blockchain: any, index: any, self: any) =>
        self.indexOf(blockchain) === index
    )
    .map((blockchain: any) => ({ [blockchain]: address }))
    .reduce((acc: any, obj: any) => {
      return { ...acc, ...obj };
    }, {});
  console.log("selectedWallets", selectedWallets);

  const confirmRouter = async () => {
    const confirmResponse = await rango.confirmRoute({
      requestId: pathRouter?.results[clickRouter.id]?.requestId,
      selectedWallets,
      destination: address,
    });
    console.log("confirmResponse", confirmResponse);
    if (confirmResponse) {
      setConfirmRouterTest(confirmResponse);
    }
  };

  useEffect(() => {
    if (pathRouter && pathRouter?.results?.length > 0) {
      confirmRouter();
    }
  }, [pathRouter]);

  const [txTest, setTxTest] = useState<any>();
  console.log("txTest", txTest);
  const handleRequestTransaction = async () => {
    try {
      const request: CreateTransactionRequest = {
        requestId: confirmRouterTest.result.requestId,
        step: 1,
        userSettings: {
          slippage: "1.0",
        },
        validations: {
          approve: true,
          balance: true,
          fee: true,
        },
      };
      const createTransactionResponse: any = await axios.post(
        `https://api.rango.exchange/tx/create?apiKey=${KIB_API_RANGO}`,
        request
      );
      console.log("createTransactionResponse", createTransactionResponse);
      if (!createTransactionResponse?.data.transaction) {
        throw new Error(
          `Error creating the transaction ${createTransactionResponse.error}`
        );
      } else {
        setTxTest(createTransactionResponse?.data.transaction);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const [step, setStep] = useState<any>(1);
  const processSwapSteps = async () => {
    for (const swap of confirmRouterTest.result.result.swaps) {
      logRouteStep(swap, step);

      // // Set RPC provider for this step
      // const rpcProvider = new ethers.JsonRpcProvider(getRpcUrlForBlockchain(meta, swap.from.blockchain));
      // const walletWithProvider = wallet.connect(rpcProvider);

      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer: any = await provider.getSigner();
      // const res = await signer.sendTransaction(approveTransaction);

      const request: CreateTransactionRequest = {
        requestId: confirmRouterTest?.result.requestId,
        step,
        userSettings: {
          slippage: "1.0",
          infiniteApprove: false,
        },
        validations: {
          approve: true,
          balance: false,
          fee: false,
        },
      };

      try {
        let createTransactionResponse = await rango.createTransaction(request);
        let tx = createTransactionResponse.transaction;

        if (!tx) {
          throw new Error(
            `Error creating the transaction ${createTransactionResponse.error}`
          );
        }

        if (tx.type === TransactionType.EVM) {
          if (tx.isApprovalTx) {
            // Sign the approve transaction
            const approveTransaction: any = {
              from: txTest.from,
              to: txTest.to,
              data: txTest.data,
              value: txTest.value,
              maxFeePerGas: txTest.maxFeePerGas,
              maxPriorityFeePerGas: txTest.maxPriorityFeePerGas,
              gasPrice: txTest.gasPrice,
              gasLimit: txTest.gasLimit,
            };
            // const { hash } = await walletWithProvider.sendTransaction(approveTransaction);
            const { hash } = await signer.sendTransaction(approveTransaction);
            logTransactionHash(hash, true);

            // Wait for approval
            while (true) {
              // await setTimeout(5_000);
              const {
                isApproved,
                currentApprovedAmount,
                requiredApprovedAmount,
                txStatus,
              } = await rango.checkApproval(txTest?.result.requestId, hash);
              logApprovalResponse(isApproved);
              if (isApproved) {
                break;
              } else if (txStatus === TransactionStatus.FAILED) {
                throw new Error("Approve transaction failed in blockchain");
              } else if (txStatus === TransactionStatus.SUCCESS) {
                throw new Error(
                  `Insufficient approve, current amount: ${currentApprovedAmount}, required amount: ${requiredApprovedAmount}`
                );
              }
            }

            // Create the main transaction if previous one was approval transaction
            createTransactionResponse = await rango.createTransaction(request);
            tx = createTransactionResponse.transaction;
            if (!tx || tx.type !== TransactionType.EVM) {
              throw new Error(
                `Error creating the transaction ${createTransactionResponse.error}`
              );
            }
          }

          // Sign the main transaction
          const mainTransaction: any = {
            from: txTest.from,
            to: txTest.to,
            data: txTest.data,
            value: txTest.value,
            maxFeePerGas: txTest.maxFeePerGas,
            maxPriorityFeePerGas: txTest.maxPriorityFeePerGas,
            gasPrice: txTest.gasPrice,
            gasLimit: txTest.gasLimit,
          };
          // const { hash } = await walletWithProvider.sendTransaction(mainTransaction);
          const { hash } = await signer.sendTransaction(mainTransaction);
          logTransactionHash(hash, false);

          // Track swap status
          while (true) {
            // await setTimeout(10_000);
            const state = await rango.checkStatus({
              requestId: confirmRouterTest?.result.requestId,
              step,
              txId: hash,
            });
            logStepStatus(state);

            const status = state.status;
            if (status === TransactionStatus.SUCCESS) {
              // Proceed with the next step of the route
              setStep((prevStep: any) => prevStep + 1);
              break;
            } else if (status === TransactionStatus.FAILED) {
              throw new Error(`Swap failed on step ${step}`);
            }
          }
        }
      } catch (error) {
        console.error(error);
        throw new Error(`Error processing swap step ${step}`);
      }
    }
  };

  // useEffect(() => {

  // }, [])

  // const handleRequest = async () => {
  //   if (!window.ethereum) {
  //     console.error("Ethereum provider not found");
  //     return;
  //   }
  //   const approveTransaction = {
  //     from: txTest?.from,
  //     to: txTest?.to,
  //     data: txTest?.data,
  //     value: txTest?.value,
  //     maxFeePerGas: txTest?.maxFeePerGas,
  //     maxPriorityFeePerGas: txTest?.maxPriorityFeePerGas,
  //     gasPrice: txTest?.gasPrice,
  //     gasLimit: txTest?.gasLimit,
  //   };
  //   try {
  //     const provider = new ethers.BrowserProvider(window.ethereum);
  //     const signer: any = await provider.getSigner();
  //     const res = await signer.sendTransaction(approveTransaction);

  //   } catch (error) {
  //     console.error("Transaction Error:", error);
  //   }
  // };

  useEffect(() => {
    if (txTest && Object.keys(txTest)?.length > 0) {
      processSwapSteps();
    }
  }, [txTest]);

  console.log("nameChainFrom", nameChainFrom);

  // const [txStatus, setTxStatus] = useState<TransactionStatusResponse | null>(
  //   null
  // );
  // const [countStep, setCountStep] = useState<number>(0);

  // const checkTransactionStatusSync = async (
  //   requestId: string,
  //   step: number,
  //   txHash: string,
  //   rangoClient: any,
  //   userSettings: any
  // ) => {
  //   console.log("step-4", step);
  //   console.log("--------------------");
  //   while (true) {
  //     const txStatus = await rangoClient
  //       .checkStatus({ requestId: requestId, step: step, txId: txHash })
  //       .catch((error: any) => {
  //         console.log(error);
  //       });
  //     if (!!txStatus) {
  //       setTxStatus(txStatus);
  //       if (
  //         !!txStatus.status &&
  //         [TransactionStatus.FAILED, TransactionStatus.SUCCESS].includes(
  //           txStatus.status
  //         )
  //       ) {
  //         if (step < countStep) {
  //           await checkApprove(requestId, step + 1, userSettings);
  //         } else {
  //           setLoading(false);
  //           setTimeout(() => {
  //             setOpenStatus(false);
  //           }, 10000);
  //         }
  //         return txStatus;
  //       }
  //     }
  //     await sleep(3000);
  //   }
  // };

  return (
    <SwapBg>
      <ModalOverlay
        component={
          <TokenAndNetwork
            isFrom={true}
            setNameChain={setNameChainFrom}
            setOpenModal={setOpenFromModal}
            setDataToken={setDataTokenFrom}
          />
        }
        open={openFromModal}
        setOpen={setOpenFromModal}
        title={"Token & Network"}
        width="550px"
      />
      <ModalOverlay
        component={
          <TokenAndNetwork
            isFrom={false}
            setNameChain={setNameChainTo}
            setOpenModal={setOpenToModal}
            setDataToken={setDataTokenTo}
          />
        }
        open={openToModal}
        setOpen={setOpenToModal}
        title={"Token & Network"}
        width="550px"
      />
      <p
        style={{
          color: "#fff",
        }}
        onClick={() => {
          open();
        }}
      >
        Connect Wallet
      </p>
      <SwapContainer>
        <SwapTop
          onClick={() => {
            // open()
            // if (confirmRouterTest?.ok) {
            //   handleRequestTransaction();
            // }
          }}
        >
          <TitleCommon text={"POWERFUL BRIDGE"} />
          <SwapBenifitTag className={theme}>
            {benifitTags.map((item, index) => {
              return (
                <li
                  style={{
                    color: item.color,
                  }}
                  key={index}
                >
                  <span
                    style={{
                      background: item.color,
                    }}
                  ></span>
                  {item.title}
                </li>
              );
            })}
          </SwapBenifitTag>
        </SwapTop>
        <BridgeContainer>
          <SwapWrapper>
            <TitleCommon text={"CROSS CHAIN"} />
            <BridgeInner>
              <BridgeForm>
                <ButtonCommon
                  onClick={() => {
                    setOpenFromModal(true);
                  }}
                >
                  Open Modal From
                </ButtonCommon>
                <BridgeSelect>
                  <p>{nameChainFrom.nameChainFrom}</p>
                  <p>-</p>
                  <p>{nameChainFrom?.nameTokenFrom}</p>
                </BridgeSelect>
                <BridgeInput>
                  <Controller
                    name="fromAmount"
                    control={control}
                    render={({ field }: any) => (
                      <SwapInputValue>
                        <InputCommon
                          {...field}
                          // disabled={address && checkingToken ? false : true}
                          // onChange={handleSendValue}
                          // onFocus={handleResetValueFrom}
                          placeHolder={"0"}
                          value={valueInputFrom}
                          onChange={onChangeInputFrom}
                          suffix={
                            <BridgeSuffix>
                              <p>
                                ~5,242 USD <span>|</span>
                              </p>
                              <span>Max</span>
                            </BridgeSuffix>
                          }
                        />
                      </SwapInputValue>
                    )}
                  />
                </BridgeInput>
              </BridgeForm>
              <BridgeSwitch>
                <img src={swap_exchange} alt="icon" loading="lazy" />
              </BridgeSwitch>
              <BridgeForm>
                <ButtonCommon
                  onClick={() => {
                    setOpenToModal(true);
                  }}
                >
                  Open Modal To
                </ButtonCommon>
                <BridgeSelect>
                  <p>{nameChainTo.nameChainTo}</p>
                  <p>on</p>
                  <p>{nameChainTo.nameTokenTo}</p>
                </BridgeSelect>
                <BridgeInput>
                  <Controller
                    name="toAmount"
                    control={control}
                    render={({ field }: any) => (
                      <SwapInputValue>
                        <InputCommon
                          {...field}
                          // disabled={address && checkingToken ? false : true}
                          // onChange={handleSendValue}
                          // onFocus={handleResetValueFrom}
                          placeHolder={"0"}
                          suffix={
                            <BridgeSuffix>
                              <p>~5,242 USD</p>
                            </BridgeSuffix>
                          }
                        />
                      </SwapInputValue>
                    )}
                  />
                </BridgeInput>
              </BridgeForm>
            </BridgeInner>
            <ButtonCommon
              onClick={() => {
                if (confirmRouterTest?.ok) {
                  handleRequestTransaction();
                }
              }}
            >
              Buy
            </ButtonCommon>
          </SwapWrapper>
          {Number(valueInputFrom) > 0 && (
            <SwapWrapper className={isLoadingRouter ? "is-loading" : ""}>
              {isLoadingRouter ? (
                <LoadingSpin />
              ) : (
                <>
                  <BridgeTabs>
                    <h2>Route</h2>
                    <SwapChartFilter className={theme} ispending={true}>
                      {routeFilter.map((item, index) => {
                        return (
                          <li
                            onClick={() => {
                              setTabClicked(item.id);
                            }}
                            className={tabClicked === item.id ? "active" : ""}
                            key={index}
                          >
                            {item.text}
                          </li>
                        );
                      })}
                    </SwapChartFilter>
                  </BridgeTabs>
                  <BridgeRouteList>
                    {pathRouter?.results?.map((item: any, index: number) => (
                      <RouteItem
                        item={item}
                        key={index}
                        setClickRouter={setClickRouter}
                        clickRouter={clickRouter}
                        number={index}
                      />
                    ))}
                  </BridgeRouteList>
                </>
              )}
            </SwapWrapper>
          )}
        </BridgeContainer>
      </SwapContainer>
    </SwapBg>
  );
};

export default Bridge;
