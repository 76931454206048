import React, { useState } from "react";
import { ReceiveWalletContainer, ReceiveWallet } from "./styled";
import { ButtonCommon } from "../../../../Layout/styled";
import { Checkbox, Switch } from "antd";
import { shortenAddress } from "../../../../utils/addressUser";
import InputCommon from "../../../../components/Common/Input";
import { Controller, useForm } from "react-hook-form";
import { SwapInputValue } from "../../../Swap/styled";

interface walletInterface {
  address: string;
  setReceiveWallet: any;
  setOpenModalWallet: any;
  receiveWallet: string;
}

const ModalWallet = ({
  address,
  setReceiveWallet,
  setOpenModalWallet,
  receiveWallet,
}: walletInterface) => {
  const [isSelect, setIsSelect] = useState(true);
  const [switchState, setSwitchState] = useState(false);
  const handleCheckSelect = (e: any) => {
    setIsSelect(e.target.checked);
  };
  const handleSwitch = (value: any) => {
    setSwitchState(value);
  };
  const { control } = useForm({ mode: "onChange" });
  const onChangeInputFrom = (e: any) => {
    setReceiveWallet(e.target.value);
  };

  return (
    <ReceiveWalletContainer>
      <ReceiveWallet>
        <div className="heading-wallet">
          <h3 className="title-wallet">Your Wallet</h3>
        </div>
        <div className="content-wallet">
          <div className="content-wallet-left">
            <div className="logo-wallet">
              <img
                src="/assets/images/bridge/icn_logo_wallet.png"
                width={38}
                height={38}
                loading="lazy"
                alt="Your Wallet"
              />
            </div>
            <div className="address-wallet">
              <p className="address">
                Wallet address:{" "}
                <span>{address ? shortenAddress(address) : "--"}</span>
              </p>
            </div>
          </div>
          <div className="content-wallet-right">
            <p className="connect-wallet">Connected</p>
          </div>
        </div>
        <ButtonCommon>
          <p>Change Wallet</p>
        </ButtonCommon>
      </ReceiveWallet>
      <ReceiveWallet>
        <div className="heading-wallet">
          <h3 className="title-wallet">Recipient Wallet</h3>
          <Switch checked={switchState} onChange={handleSwitch} />
        </div>
        <div className="content-wallet">
          <div className="content-wallet-left">
            <div className="logo-wallet">
              <img
                src="/assets/images/bridge/icn_logo_wallet_02.png"
                width={38}
                height={38}
                loading="lazy"
                alt="Your Wallet"
              />
            </div>
            <div className="address-wallet">
              <div className="address">
                {/* <span>Address or ENS</span> */}
                <Controller
                  name="recriveAddress"
                  control={control}
                  render={({ field }: any) => (
                    <SwapInputValue>
                      <InputCommon
                        {...field}
                        // disabled={address && checkingToken ? false : true}
                        // onChange={handleSendValue}
                        // onFocus={handleResetValueFrom}
                        placeHolder={"Address or ENS"}
                        value={receiveWallet}
                        onChange={onChangeInputFrom}
                      />
                    </SwapInputValue>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="checkbox-wallet">
          <Checkbox onChange={handleCheckSelect} checked={isSelect}>
            This address is correct and not an exchange wallet. Any tokens sent
            to the wrong address will be impossible to retrieve.
          </Checkbox>
        </div>
        <ButtonCommon
          disabled={!isSelect ? true : false}
          onClick={() => setOpenModalWallet(false)}
        >
          <p>Done</p>
        </ButtonCommon>
      </ReceiveWallet>
    </ReceiveWalletContainer>
  );
};

export default ModalWallet;
