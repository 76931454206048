import { useContext, useEffect, useRef, useState } from "react";
import ModalOverlay from "../../components/Common/ModalOverlay";
import TitleCommon from "../../components/Common/Title";
import TokenAndNetwork from "../Bridge/Modal/Token&Network";
import {
  SwapBenifitTag,
  SwapBg,
  SwapContainer,
  SwapExtension,
  SwapHeader,
  SwapInputValue,
  SwapWrapper,
} from "../Swap/styled";
import { ContextProviderWrapper } from "../../components/Context";
import {
  BridgeBottom,
  BridgeContainer,
  BridgeContainerInner,
  BridgeContent,
  BridgeContentHeading,
  BridgeContentInner,
  BridgeContentRouter,
  BridgeContentTable,
  BridgeInput,
  HoverSelectToken,
} from "../Bridge/styled";
import doc from "../../assets/Dashboard/Swap/doc.svg";
import doc_active from "../../assets/Dashboard/Swap/doc_active.svg";
import doc_light from "../../assets/Dashboard/Swap/doc_light.svg";
import chart_light from "../../assets/Dashboard/Swap/chart_light.svg";
import chart from "../../assets/Dashboard/Swap/chart.svg";
import chart_active from "../../assets/Dashboard/Swap/chart_active.svg";
import settings from "../../assets/Dashboard/Swap/settings.svg";
import settings_active from "../../assets/Dashboard/Swap/settings_active.svg";
import settings_light from "../../assets/Dashboard/Swap/settings_light.svg";
import caret_down_light from "../../assets/Dashboard/Swap/caret_down_light.svg";
import { Controller, useForm } from "react-hook-form";
import InputCommon from "../../components/Common/Input";
import { ButtonCommon } from "../../Layout/styled";
import RouterV2 from "./RouterIemv2";
import {
  CreateTransactionRequest,
  RangoClient,
  TransactionStatus,
  TransactionType,
} from "rango-sdk";
import { useDebounce } from "../../hooks/useDebounce";
import ModalWallet from "../Bridge/Modal/Wallet";
import BlockFee from "./BlockFee";
import { useAppKitAccount } from "@reown/appkit/react";
import axios from "axios";
import {
  logApprovalResponse,
  logRouteStep,
  logStepStatus,
  logTransactionHash,
} from "../../utils/logger";
import { ethers } from "ethers";
import { shortenAddress } from "../../utils/addressUser";
import { Popover, Spin } from "antd";
import toast from "react-hot-toast";

declare const window: Window & typeof globalThis & { ethereum: any };

const BridgeV2 = () => {
  const KIB_API_RANGO: any = process.env.REACT_APP_KIBBLE_RANGO;
  const INPUT_DEBOUNCE = 1000;
  const rango = new RangoClient(KIB_API_RANGO);
  const { address }: any = useAppKitAccount();
  const { theme, isDesktop, isMobile } = useContext(ContextProviderWrapper)!;
  const { control } = useForm({ mode: "onChange" });
  const [openFromModal, setOpenFromModal] = useState(false);
  const [nameChainFrom, setNameChainFrom] = useState({
    nameChainFrom: "",
    logoChainFrom: "",
    nameTokenFrom: "--",
    logoTokenFrom: "",
    priceTokenFrom: 0,
  });
  const [nameChainTo, setNameChainTo] = useState({
    nameChaninTo: "",
    logoChainTo: "",
    nameTokenTo: "--",
    logoTokenTo: "",
    priceTokenTo: 0,
  });
  const [pathRouter, setPathRouter] = useState<any>();
  const [dataTokenFrom, setDataTokenFrom] = useState<any>();
  const [openToModal, setOpenToModal] = useState(false);
  const [openModalWallet, setOpenModalWallet] = useState(false);
  const [dataTokenTo, setDataTokenTo] = useState<any>();
  const [valueInputFrom, setValueInputFrom] = useState();
  const [isShowRouterMobile, setIsShowRouterMobile] = useState(false);
  const [isLoadingRouter, setIsLoadingRouter] = useState<boolean | null>(false);
  const [clickRouter, setClickRouter] = useState({
    id: 0,
    requestId: "",
  });
  console.log("clickRouter", clickRouter);
  const [receiveWallet, setReceiveWallet] = useState<string>("");
  const [selectedToData, setSelectedToData] = useState({
    amount: 0,
    usdPrice: 0,
  });
  const [chainList, setChainList] = useState<any>([]);
  const [balance, setBalance] = useState<any>();
  const [isLoadingBalance, setIsLoadingBalance] = useState(false);
  const [isLoadingBuy, setIsLoadingBuy] = useState(false);
  const [routerDetail, setRouterDetail] = useState<any>({
    totalTime: "",
    totalNetworkFee: "",
    intermediaryLogos: [],
  });
  const intervalRef = useRef<any>(null);

  //format number
  const formatToTwoDecimals = (number: number) => {
    return Math.floor(number * 100) / 100;
  };
  const benifitTags = [
    {
      title: "Free gas",
      color: theme === "light" ? "#0FF586" : "#0FF586",
    },
    {
      title: "Lighting fast",
      color: theme === "light" ? "#E6A519" : "#F0CC54",
    },
    {
      title: "Best rate",
      color: theme === "light" ? "#DC622E" : "#DC622E",
    },
  ];

  //get router
  const getRouter = async () => {
    setIsLoadingRouter(true);
    const routingResponse = await rango.getAllRoutes({
      from: {
        blockchain: `${dataTokenFrom?.blockchain}`,
        symbol: `${dataTokenFrom?.symbol}`,
        address: dataTokenFrom?.address ? dataTokenFrom?.address : null,
      },
      to: {
        blockchain: `${dataTokenTo?.blockchain}`,
        symbol: `${dataTokenTo?.symbol}`,
        address: `${dataTokenTo?.address}`,
      },
      amount: `${valueInputFrom}`,
      slippage: "1.0",
    });
    if (routingResponse) {
      setPathRouter(routingResponse);
      setIsLoadingRouter(false);
    }
    console.log(routingResponse);
  };
  // const startPollingRouter = () => {
  //   if (intervalRef.current) {
  //     clearInterval(intervalRef.current); // Clear previous interval if exists
  //   }
  //   intervalRef.current = setInterval(() => {
  //     console.log("Fetching router...");
  //     getRouter();
  //   }, 20000); // 20s
  // };
  useEffect(() => {
    if (
      valueInputFrom &&
      Number(valueInputFrom) > 0 &&
      nameChainFrom.nameTokenFrom !== "--" &&
      nameChainTo.nameTokenTo !== "--"
    )
      // {
      //   startPollingRouter();
      // }

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
  }, [valueInputFrom, nameChainFrom, nameChainTo]);

  const debouncedHandleGetBridge = useDebounce(() => {
    getRouter();
    setIsShowRouterMobile(true);
  }, INPUT_DEBOUNCE);

  // Input change handler
  const onChangeInputFrom = (e: any) => {
    setValueInputFrom(e.target.value);
  };

  // Trigger debounced function on input change
  useEffect(() => {
    if (
      valueInputFrom &&
      Number(valueInputFrom) > 0 &&
      nameChainFrom.nameTokenFrom !== "--" &&
      nameChainTo.nameTokenTo !== "--"
    ) {
      debouncedHandleGetBridge();
    }
  }, [valueInputFrom, nameChainFrom, nameChainTo]);

  // list extension
  const listExtensions = [
    {
      id: 3,
      icon: theme === "dark" ? doc : doc_light,
      activeIcon: doc_active,
    },
    {
      id: 1,
      icon: theme === "dark" ? chart : chart_light,
      activeIcon: chart_active,
    },
    {
      id: 2,
      icon: theme === "dark" ? settings : settings_light,
      activeIcon: settings_active,
    },
  ];

  // Function swap token
  const [confirmRouterTest, setConfirmRouterTest] = useState<any>();
  console.log("confirmRouterTest", confirmRouterTest);
  const selectedWallets = pathRouter?.results[clickRouter.id]?.swaps
    ?.flatMap((swap: any) => [swap.from.blockchain, swap.to.blockchain])
    .filter(
      (blockchain: any, index: any, self: any) =>
        self.indexOf(blockchain) === index
    )
    .map((blockchain: any) => ({ [blockchain]: address }))
    .reduce((acc: any, obj: any) => {
      return { ...acc, ...obj };
    }, {});
  console.log("selectedWallets", selectedWallets);

  const confirmRouter = async () => {
    const confirmResponse = await rango.confirmRoute({
      requestId: pathRouter?.results[clickRouter.id]?.requestId,
      selectedWallets,
      destination: address,
    });
    console.log("confirmResponse", confirmResponse);
    if (confirmResponse) {
      setConfirmRouterTest(confirmResponse);
    }
  };

  useEffect(() => {
    if (pathRouter && pathRouter?.results?.length > 0) {
      confirmRouter();
    }
  }, [pathRouter]);

  const [txTest, setTxTest] = useState<any>();
  console.log("txTest", txTest);
  const handleRequestTransaction = async () => {
    try {
      setIsLoadingBuy(true);
      const request: CreateTransactionRequest = {
        requestId: confirmRouterTest.result.requestId,
        step: 1,
        userSettings: {
          slippage: "1.0",
        },
        validations: {
          approve: true,
          balance: true,
          fee: true,
        },
      };
      const createTransactionResponse: any = await axios.post(
        `https://api.rango.exchange/tx/create?apiKey=${KIB_API_RANGO}`,
        request
      );
      console.log("createTransactionResponse", createTransactionResponse);
      if (createTransactionResponse?.data?.error) {
        toast.error(createTransactionResponse?.data?.error);
      }
      if (!createTransactionResponse?.data.transaction) {
        throw new Error(
          `Error creating the transaction ${createTransactionResponse.error}`
        );
      } else {
        setTxTest(createTransactionResponse?.data.transaction);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoadingBuy(false);
    }
  };

  const [step, setStep] = useState<any>(1);
  const processSwapSteps = async () => {
    for (const swap of confirmRouterTest.result.result.swaps) {
      logRouteStep(swap, step);
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer: any = await provider.getSigner();
      const request: CreateTransactionRequest = {
        requestId: confirmRouterTest?.result.requestId,
        step,
        userSettings: {
          slippage: "1.0",
          infiniteApprove: false,
        },
        validations: {
          approve: true,
          balance: false,
          fee: false,
        },
      };
      try {
        let createTransactionResponse = await rango.createTransaction(request);
        let tx = createTransactionResponse.transaction;

        if (!tx) {
          throw new Error(
            `Error creating the transaction ${createTransactionResponse.error}`
          );
        }

        if (tx.type === TransactionType.EVM) {
          if (tx.isApprovalTx) {
            // Sign the approve transaction
            const approveTransaction: any = {
              from: txTest.from,
              to: txTest.to,
              data: txTest.data,
              value: txTest.value,
              maxFeePerGas: txTest.maxFeePerGas,
              maxPriorityFeePerGas: txTest.maxPriorityFeePerGas,
              gasPrice: txTest.gasPrice,
              gasLimit: txTest.gasLimit,
            };
            // const { hash } = await walletWithProvider.sendTransaction(approveTransaction);
            const { hash } = await signer.sendTransaction(approveTransaction);
            logTransactionHash(hash, true);

            // Wait for approval
            while (true) {
              // await setTimeout(5_000);
              const {
                isApproved,
                currentApprovedAmount,
                requiredApprovedAmount,
                txStatus,
              } = await rango.checkApproval(txTest?.result.requestId, hash);
              logApprovalResponse(isApproved);
              if (isApproved) {
                break;
              } else if (txStatus === TransactionStatus.FAILED) {
                throw new Error("Approve transaction failed in blockchain");
              } else if (txStatus === TransactionStatus.SUCCESS) {
                throw new Error(
                  `Insufficient approve, current amount: ${currentApprovedAmount}, required amount: ${requiredApprovedAmount}`
                );
              }
            }

            // Create the main transaction if previous one was approval transaction
            createTransactionResponse = await rango.createTransaction(request);
            tx = createTransactionResponse.transaction;
            if (!tx || tx.type !== TransactionType.EVM) {
              throw new Error(
                `Error creating the transaction ${createTransactionResponse.error}`
              );
            }
          }

          // Sign the main transaction
          const mainTransaction: any = {
            from: txTest.from,
            to: txTest.to,
            data: txTest.data,
            value: txTest.value,
            maxFeePerGas: txTest.maxFeePerGas,
            maxPriorityFeePerGas: txTest.maxPriorityFeePerGas,
            gasPrice: txTest.gasPrice,
            gasLimit: txTest.gasLimit,
          };
          const { hash } = await signer.sendTransaction(mainTransaction);
          logTransactionHash(hash, false);

          // Track swap status
          while (true) {
            // await setTimeout(10_000);
            const state = await rango.checkStatus({
              requestId: confirmRouterTest?.result.requestId,
              step,
              txId: hash,
            });
            logStepStatus(state);

            const status = state.status;
            if (status === TransactionStatus.SUCCESS) {
              // Proceed with the next step of the route
              setStep((prevStep: any) => prevStep + 1);
              break;
            } else if (status === TransactionStatus.FAILED) {
              throw new Error(`Swap failed on step ${step}`);
            }
          }
        }
      } catch (error) {
        console.error(error);
        throw new Error(`Error processing swap step ${step}`);
      }
    }
  };

  useEffect(() => {
    if (txTest && Object.keys(txTest)?.length > 0) {
      processSwapSteps();
    }
  }, [txTest]);

  // get balance
  const calculateBalance = (amount: string, decimals: number) => {
    return (Number(amount) / Math.pow(10, decimals)).toLocaleString(undefined, {
      maximumFractionDigits: 6,
    });
  };

  const getWalletDetails = async () => {
    setIsLoadingBalance(true);
    try {
      const response = await axios.get(
        `https://api.rango.exchange/wallets/details?address=${nameChainFrom.nameChainFrom}.${address}&apiKey=${KIB_API_RANGO}`
      );
      console.log("Wallet Details Response:", response);

      const wallet = response?.data?.wallets[0];
      if (wallet && wallet.balances) {
        const matchedToken = wallet.balances.find(
          (balance: any) =>
            balance.asset.symbol.toLowerCase() ===
            nameChainFrom.nameTokenFrom.toLowerCase()
        );

        if (matchedToken) {
          const balance = calculateBalance(
            matchedToken.amount.amount,
            matchedToken.amount.decimals
          );
          setBalance(balance);
          if (balance) {
            setIsLoadingBalance(false);
          }
        } else {
          console.log("Token not found:", nameChainFrom.nameTokenFrom);
          setBalance(0);
          return "--";
        }
      } else {
        console.log("No wallets or balances found.");
        setBalance(0);
        return "--";
      }
    } catch (error) {
      console.error("Error fetching wallet details:", error);
      return "--";
    } finally {
      setIsLoadingBalance(false);
    }
  };

  // Fetch wallet details on component load or address change
  useEffect(() => {
    if (nameChainFrom.nameChainFrom) {
      getWalletDetails();
    }
  }, [nameChainFrom, address]);

  //content-hover
  const content = (
    <HoverSelectToken>
      <p>Please select chain and token</p>
    </HoverSelectToken>
  );

  return (
    <SwapBg>
      <ModalOverlay
        component={
          <TokenAndNetwork
            isFrom={true}
            setNameChain={setNameChainFrom}
            setOpenModal={setOpenFromModal}
            setDataToken={setDataTokenFrom}
            setChainList={setChainList}
            chainList={chainList}
          />
        }
        open={openFromModal}
        setOpen={setOpenFromModal}
        title={"Token & Network"}
        width="550px"
      />
      <ModalOverlay
        component={
          <TokenAndNetwork
            isFrom={false}
            setNameChain={setNameChainTo}
            setOpenModal={setOpenToModal}
            setDataToken={setDataTokenTo}
            setChainList={setChainList}
            chainList={chainList}
          />
        }
        open={openToModal}
        setOpen={setOpenToModal}
        title={"Token & Network"}
        width="550px"
      />
      <ModalOverlay
        component={
          <ModalWallet
            address={address}
            setReceiveWallet={setReceiveWallet}
            setOpenModalWallet={setOpenModalWallet}
            receiveWallet={receiveWallet}
          />
        }
        open={openModalWallet}
        setOpen={setOpenModalWallet}
        title={"Receive Wallet"}
        width="550px"
      />
      <BridgeContainerInner>
        <SwapContainer>
          <TitleCommon text={"POWERFUL BRIDGE"} />
          <SwapBenifitTag className={theme}>
            {benifitTags.map((item, index) => {
              return (
                <li
                  style={{
                    color: item.color,
                  }}
                  key={index}
                >
                  <span
                    style={{
                      background: item.color,
                    }}
                  ></span>
                  {item.title}
                </li>
              );
            })}
          </SwapBenifitTag>
          <BridgeContentTable>
            <BridgeContainer className="bridge-container-left">
              <SwapWrapper>
                <SwapHeader>
                  <TitleCommon text={"CROSS CHAIN"} />
                  <SwapExtension className={theme}>
                    {listExtensions.map((item, index) => {
                      return (
                        <li
                          // onClick={() => {
                          //   setActiveFilter(item.id);
                          //   if (item.id === 1) {
                          //     setOpenChart(!openChart);
                          //   }
                          //   if (item.id === 3) {
                          //     setIsShowHistory(true);
                          //   }
                          // }}
                          key={index}
                        >
                          <img src={item.icon} alt="icon" />
                        </li>
                      );
                    })}
                  </SwapExtension>
                </SwapHeader>
                <BridgeContent>
                  <BridgeContentInner>
                    <BridgeContentHeading>
                      <div className="block-left">
                        <p>
                          Pay:{" "}
                          <span className="is-address">
                            {address ? shortenAddress(address) : "--"}
                          </span>
                        </p>
                        <img
                          className="arrow-address"
                          src={caret_down_light}
                          width={17}
                          height={15}
                          loading="lazy"
                          alt="Icon Arrow"
                        />
                      </div>
                      <Popover
                        content={content}
                        title="Select Chain and Token"
                        trigger="hover"
                      >
                        <div className="block-right">
                          <div
                            className="select-token"
                            onClick={() => {
                              setOpenFromModal(true);
                            }}
                          >
                            <div className="logo-token">
                              {nameChainFrom.logoTokenFrom ? (
                                <img
                                  src={nameChainFrom.logoTokenFrom}
                                  width={23}
                                  height={23}
                                  loading="lazy"
                                  alt="Icon"
                                />
                              ) : (
                                <div className="no-icon"></div>
                              )}
                            </div>
                            <p className="name-token">
                              {nameChainFrom.nameTokenFrom}
                            </p>
                            <div className="icon-arrow-token">
                              <img
                                src={caret_down_light}
                                width={17}
                                height={15}
                                loading="lazy"
                                alt="Icon Arrow"
                              />
                            </div>
                          </div>
                          <div className="block-chain">
                            {nameChainFrom.logoChainFrom ? (
                              <img
                                src={nameChainFrom.logoChainFrom}
                                width={17}
                                height={17}
                                loading="lazy"
                                alt="Icon chain"
                              />
                            ) : (
                              <div className="no-icon"></div>
                            )}
                          </div>
                        </div>
                      </Popover>
                    </BridgeContentHeading>
                    <BridgeInput>
                      <Controller
                        name="fromAmount"
                        control={control}
                        render={({ field }: any) => (
                          <SwapInputValue>
                            <InputCommon
                              {...field}
                              // disabled={address && checkingToken ? false : true}
                              // onChange={handleSendValue}
                              // onFocus={handleResetValueFrom}
                              placeHolder={"0"}
                              value={valueInputFrom}
                              onChange={onChangeInputFrom}
                            />
                          </SwapInputValue>
                        )}
                      />
                    </BridgeInput>
                    <BridgeBottom>
                      <div className="content-left">
                        <p className="number-token">
                          {valueInputFrom
                            ? `~${formatToTwoDecimals(
                                nameChainFrom.priceTokenFrom *
                                  Number(valueInputFrom)
                              )}`
                            : "--"}
                          <span className="token"> USD</span>
                        </p>
                      </div>
                      <div className="content-right">
                        <p>
                          Bal:{" "}
                          {isLoadingBalance ? (
                            <Spin size="small" />
                          ) : balance ? (
                            balance
                          ) : (
                            0
                          )}
                          <span
                            className="max"
                            onClick={() => setValueInputFrom(balance)}
                          >
                            Max
                          </span>
                        </p>
                      </div>
                    </BridgeBottom>
                  </BridgeContentInner>
                </BridgeContent>
                {isMobile && (
                  <BridgeContentRouter>
                    <div
                      className="bridge-content-router-inner"
                      onClick={() =>
                        !isDesktop &&
                        pathRouter?.results?.length > 0 &&
                        setIsShowRouterMobile(true)
                      }
                    >
                      <div className="router-left">
                        <p className="text-left">
                          <span>Fee</span>
                          {routerDetail.totalNetworkFee
                            ? `$${formatToTwoDecimals(
                                routerDetail.totalNetworkFee
                              )}`
                            : 0}
                        </p>
                        {/* <img
                          src="/assets/images/bridge/icn_down_small_white.svg"
                          width={14}
                          height={14}
                          loading="lazy"
                          alt=""
                        /> */}
                      </div>
                      <div className="router-right">
                        <div className="router-middle">
                          <img
                            src="/assets/images/bridge/icn_down_white.svg"
                            width={24}
                            height={24}
                            loading="lazy"
                            alt="Icon Arrow Down"
                          />
                        </div>
                        <div className="router-right-inner">
                          <div className="block-images">
                            {routerDetail?.intermediaryLogos?.length > 0 ? (
                              routerDetail?.intermediaryLogos?.map(
                                (item: any, index: number) => (
                                  <img
                                    key={index}
                                    src={item}
                                    width={28}
                                    height={28}
                                    loading="lazy"
                                    alt=""
                                  />
                                )
                              )
                            ) : (
                              <div className="no-icon"></div>
                            )}
                          </div>
                          <div className="block-steps">
                            <p>
                              {pathRouter ? pathRouter?.results?.length : 0}{" "}
                              Stops{" "}
                              <img
                                src="/assets/images/bridge/icn_down_small_white.svg"
                                width={14}
                                height={14}
                                loading="lazy"
                                alt=""
                              />
                            </p>
                          </div>
                          <div className="block-time">
                            <p>
                              {routerDetail.totalTime
                                ? routerDetail.totalTime
                                : 0}
                              s
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </BridgeContentRouter>
                )}

                <BridgeContent>
                  <BridgeContentInner>
                    <BridgeContentHeading>
                      <div
                        className="block-left"
                        // onClick={() => setOpenModalWallet(true)}
                      >
                        <p>
                          Pay:{" "}
                          <span className="is-address">
                            {address
                              ? receiveWallet
                                ? shortenAddress(receiveWallet)
                                : shortenAddress(address)
                              : "--"}
                          </span>
                        </p>
                        <img
                          className="arrow-address"
                          src={caret_down_light}
                          width={17}
                          height={15}
                          loading="lazy"
                          alt="Icon Arrow"
                        />
                      </div>
                      <Popover
                        content={content}
                        title="Select Chain and Token"
                        trigger="hover"
                      >
                        <div className="block-right">
                          <div
                            className="select-token"
                            onClick={() => {
                              setOpenToModal(true);
                            }}
                          >
                            <div className="logo-token">
                              {nameChainTo.logoTokenTo ? (
                                <img
                                  src={nameChainTo.logoTokenTo}
                                  width={23}
                                  height={23}
                                  loading="lazy"
                                  alt="Icon"
                                />
                              ) : (
                                <div className="no-icon"></div>
                              )}
                            </div>
                            <p className="name-token">
                              {nameChainTo.nameTokenTo}
                            </p>
                            <div className="icon-arrow-token">
                              <img
                                src={caret_down_light}
                                width={17}
                                height={15}
                                loading="lazy"
                                alt="Icon Arrow"
                              />
                            </div>
                          </div>
                          <div className="block-chain">
                            {nameChainTo.logoChainTo ? (
                              <img
                                src={nameChainTo.logoChainTo}
                                width={17}
                                height={17}
                                loading="lazy"
                                alt="Icon chain"
                              />
                            ) : (
                              <div className="no-icon"></div>
                            )}
                          </div>
                        </div>
                      </Popover>
                    </BridgeContentHeading>
                    <BridgeInput>
                      <Controller
                        name="fromTo"
                        control={control}
                        render={({ field }: any) => (
                          <SwapInputValue>
                            <InputCommon
                              {...field}
                              disabled={true}
                              // onChange={handleSendValue}
                              // onFocus={handleResetValueFrom}
                              placeHolder={"0"}
                              value={selectedToData?.amount}
                              // onChange={onChangeInputFrom}
                            />
                          </SwapInputValue>
                        )}
                      />
                    </BridgeInput>
                    <BridgeBottom>
                      <div className="content-left">
                        <p className="number-token">
                          {selectedToData?.amount > 0
                            ? `~${formatToTwoDecimals(
                                selectedToData?.amount *
                                  selectedToData?.usdPrice
                              )}`
                            : "--"}{" "}
                          <span className="token">USD</span>
                        </p>
                      </div>
                      {/* <div className="content-right">
                        <p>
                          Bal: $12,053.32<span>Max</span>
                        </p>
                      </div> */}
                    </BridgeBottom>
                  </BridgeContentInner>
                </BridgeContent>
                <ButtonCommon
                  className="button-buy"
                  onClick={() => {
                    if (confirmRouterTest?.ok) {
                      handleRequestTransaction();
                    }
                  }}
                  disabled={confirmRouterTest?.ok ? false : true}
                >
                  {isLoadingBuy ? <Spin size="small" /> : <p>Buy</p>}
                </ButtonCommon>
              </SwapWrapper>
            </BridgeContainer>
            <BridgeContainer
              className={`bridge-container-right ${
                isShowRouterMobile ? "is-show" : ""
              }`}
            >
              <RouterV2
                pathRouter={pathRouter}
                setIsShowRouterMobile={setIsShowRouterMobile}
                isLoadingRouter={isLoadingRouter}
                setClickRouter={setClickRouter}
                clickRouter={clickRouter}
                setSelectedToData={setSelectedToData}
                setRouterDetail={setRouterDetail}
              />
            </BridgeContainer>
          </BridgeContentTable>
        </SwapContainer>
      </BridgeContainerInner>
      {/* Block Fee */}
      {/* <BlockFee /> */}
    </SwapBg>
  );
};

export default BridgeV2;
