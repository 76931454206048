import { useAppKit, useAppKitAccount } from "@reown/appkit/react";
import { shortenAddress } from "../../../utils/addressUser";
import { useContext } from "react";
import { ContextProviderWrapper } from "../../Context";
import balance_light from "../../../assets/Dashboard/Common/balance_icon_light.svg";
import balance from "../../../assets/Dashboard/Common/balance_icon.svg";
import { ActionConnect } from "../Dashboard/styled";

const WalletMultichain = () => {
  const { open } = useAppKit();
  const { address } = useAppKitAccount();
  const { theme } = useContext(ContextProviderWrapper)!;
  return (
    <ActionConnect
      className={theme}
      onClick={() => {
        open();
      }}
    >
      {address ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <figure>
            <img src={theme === "light" ? balance_light : balance} alt="icon" />
          </figure>
          <p>
            {address.slice(0, 4)}...{address.slice(-4)}
          </p>
        </div>
      ) : (
        <>
          <figure>
            <img src={theme === "light" ? balance_light : balance} alt="icon" />
          </figure>
          <p>Connect Wallet</p>
        </>
      )}
    </ActionConnect>
  );
};

export default WalletMultichain;
